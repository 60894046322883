import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import Loader from '../components/Loader';
import "./css/GeneralStyles.css";
import "./css/Admin.css";
import AuthAdmin from '../components/AuthAdmin';
import { useSocket } from "../services/socket";

export default function AdminNotifications() {
    const token = localStorage.getItem("cookie_token");
    const navigate = useNavigate();
    const { sendNotificacion } = useSocket();
    const [loadContent, setLoadContent] = useState(false);
    const [commsGlobal, setCommsGlobal] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [text, setText] = useState("");
    const [edit, setEdit] = useState(false);
    const [selectComm, setSelectComm] = useState(null);
    const isAdmin = AuthAdmin();

    /**
     * Obtiene las notificaciones globales si el usuario es administrador.
     */
    useEffect(() => {
        if (isAdmin) {
            getGlobalComm();
        }
    }, [isAdmin]);

    /**
     * Obtiene las notificaciones globales del servidor.
     */
    const getGlobalComm = () => {
        fetch(process.env.REACT_APP_API_URL + "/api/get-global-notifications", {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setCommsGlobal(data);
                setLoadContent(true);
            });
    };

    /**
     * Elimina una notificación global del servidor.
     * 
     * Params: idComm (string) - El ID de la notificación a eliminar.
     * Return: Ninguno.
     */
    const deleteComm = (idComm) => {
        fetch(process.env.REACT_APP_API_URL + "/api/delete-notification/" + idComm, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                let indexComm = commsGlobal.findIndex(comm => comm.id === idComm);
                let temporalComms = [...commsGlobal];
                temporalComms.splice(indexComm, 1);
                setCommsGlobal(temporalComms);
                sendNotificacion(0);
            });
    }

    /**
     * Crea una nueva notificación global y la envía al servidor.
     */
    const createComm = () => {
        setShowModal(false);
        setLoadContent(false);
        let formData = new FormData();
        formData.append("text", text);

        fetch(process.env.REACT_APP_API_URL + "/api/create-notification", {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            body: formData
        })
            .then((response) => response.json())
            .then((data) => {
                setCommsGlobal(data);
                setLoadContent(true);
                sendNotificacion(0);
            });
    }

    /**
     * Activa el modo de edición de una notificación global, establece la notificación seleccionada para editar, 
     * y muestra el modal de edición con el texto de la notificación seleccionada.
     * 
     * Params: comm (object) - La notificación global seleccionada para editar.
     * Return: Ninguno.
     */
    const editComm = (comm) => {
        setEdit(true);
        setSelectComm(comm);
        setText(comm.text);
        setShowModal(true);
    }

    /**
     * Cierra el modal de edición de notificaciones globales y restablece el estado del texto y el modo de edición.
     */
    const closeModal = () => {
        setText("");
        setShowModal(false);
        setEdit(false);
    }

    /**
     * Abre el modal de creación de notificaciones globales y restablece el estado del texto y el modo de edición.
     */
    const openModal = () => {
        setText("");
        setEdit(false);
        setShowModal(true);
    }

    /**
     * Envía la edición de una notificación global al servidor, actualiza el estado de carga del contenido y cierra el modal de edición.
     */
    const sendEditComm = () => {
        setShowModal(false);
        setLoadContent(false);
        let formData = new FormData();
        formData.append("id", selectComm.id);
        formData.append("text", text);

        fetch(process.env.REACT_APP_API_URL + "/api/edit-notification/", {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            body: formData
        })
            .then((response) => response.json())
            .then((data) => {
                setCommsGlobal(data);
                setLoadContent(true);
                sendNotificacion(0);
            });
    }

    if (isAdmin) {
        return (
            <div className="container">
                <div className="content-wo-footer">
                    <div className="header-bar blue-bar">
                        <button className="button-bar blue-bar-title" size="icon" variant="ghost" onClick={() => navigate("/admin")}>
                            <Icon className='icon-bar back-icon-bar' icon="material-symbols:arrow-back-ios" />
                        </button>
                        <h1 className="title-bar blue-bar-title">Admin</h1>
                        <button className="button-bar blue-bar-title" size="icon" variant="ghost" onClick={() => openModal()}>
                            <Icon className='icon-bar back-icon-bar' icon="material-symbols:add-rounded" />
                        </button>
                    </div>
                    <div className='transparent-container'>
                        {loadContent ? commsGlobal.map((comm, index) => {
                            return (
                                <div className='admin-notify margin-top border' key={index}>
                                    <div className='service-info'>
                                        <div>
                                            <p className='job-user'>{comm.text}</p>
                                        </div>
                                    </div>
                                    <div className='buttons-admin'>
                                        <Icon icon="tabler:pencil" className='edit' onClick={() => editComm(comm)} />
                                        <Icon icon="tabler:trash" className='delete' onClick={() => deleteComm(comm.id)} />
                                    </div>
                                </div>
                            )
                        }) : (
                            <Loader />
                        )}
                    </div>
                    {showModal && (
                        <div className="modal-bg">
                            <div className="modal rating-modal">
                                <div className="fade-in-animation">
                                    <h4>{edit ? "Editar notificación" : "Crear notificación"}</h4>
                                    <button className="close-button" onClick={() => closeModal()}>
                                        <Icon icon="tabler:x" />
                                    </button>
                                    <div className='rating-modal-text margin-top'>
                                        <textarea value={text} onChange={(e) => setText(e.target.value)} placeholder='Texto de la notificación' />
                                    </div>
                                    <div className="modal-buttons">
                                        <button className="cancel modal-button" onClick={() => closeModal()}>Cancelar</button>
                                        {edit ? (
                                            <button className="rating-btn modal-button" onClick={() => sendEditComm()}>Editar</button>
                                        ) : (
                                            <button className="rating-btn modal-button" onClick={() => createComm()}>Añadir</button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
};
