import React, { useEffect } from 'react';
import "./css/Switch.css";
import { Icon } from '@iconify/react';

export default function Switch({ checkedCards, changeCheckedCards, setLoadingCards }) {
    /**
     * Cambia el estado de las tarjetas seleccionadas.
     *
     * Params: temporalChecked (boolean[]) - Arreglo temporal de estado de las tarjetas seleccionadas.
     * Return: Ninguno.
     */
    const toogleCheckedCards = (temporalChecked) => {
        setLoadingCards(true);
        changeCheckedCards(temporalChecked);
    }

    return (
        <label className="switch fade-in-animation">
            <input
                type="checkbox"
                checked={checkedCards}
                onChange={() => toogleCheckedCards(!checkedCards)}
            />
            <div className="slider">
                <div className="circle">
                    {!checkedCards ? <Icon icon="tabler:x" /> : <Icon icon="tabler:check" />}
                </div>
            </div>
        </label>
    );
}