import React, { useEffect } from 'react';
import './css/FooterMenu.css';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';

export default function FooterMenu() {
    const navigate = useNavigate();

    return (
        <div className='footer'>
            <button className='button-footer' onClick={() => navigate("/dashboard")}><div><Icon className='icon-footer' icon="tabler:home" />Inicio</div></button>
            <button className='button-footer' onClick={() => navigate("/services")}><div><Icon className='icon-footer' icon="tabler:search" />Servicios</div></button>
            <button className='button-footer' onClick={() => navigate("/calendar")}><div><Icon className='icon-footer' icon="tabler:calendar-month" />Calendario</div></button>
            <button className='button-footer' onClick={() => navigate("/profile")}><div><Icon className='icon-footer' icon="tabler:brand-baidu" />Perfil</div></button>
        </div>
    );
};

