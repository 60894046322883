import React, { useState, useEffect, useRef } from 'react';
import { Marker, InfoWindow, StandaloneSearchBox } from '@react-google-maps/api';
import customMarker from "../images/marker.png";
import { Icon } from '@iconify/react';

export default function Mapa({ places, setPlaces, currentLocation, setCurrentLocation }) {
    const [infoWindowOpen, setInfoWindowOpen] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [chargedPlaces, setChargedPlaces] = useState(false);
    const [yourCurrentLocation, setYourCurrentLocation] = useState(currentLocation);
    const [viewed, setViewed] = useState(false);
    const [clickedMarker, setClickedMarker] = useState(null);
    const containerRef = useRef(null);

    /**
     * Maneja el desplazamiento dentro del contenedor para mostrar la ventana de información del elemento visible.
     */
    useEffect(() => {
        const handleScroll = () => {
            const container = containerRef.current;
            const elements = container.querySelectorAll('.veterinary-card');
            const containerRect = container.getBoundingClientRect();
            let visibleElementIndex = null;
            elements.forEach((element, index) => {
                const rect = element.getBoundingClientRect();
                if (rect.left >= containerRect.left && rect.right <= containerRect.right) {
                    visibleElementIndex = index;
                }
            });
            if (visibleElementIndex != null) {
                setInfoWindowOpen(visibleElementIndex);
            }
        };

        const container = containerRef.current;
        container.addEventListener('scroll', handleScroll);

        return () => {
            container.removeEventListener('scroll', handleScroll);
        };
    }, [places]);

    /**
     * Selecciona un lugar y realiza una búsqueda de lugares cercanos y sus detalles.
     *
     * Params: location (object) - La ubicación seleccionada.
     * Return: Ninguno.
     */
    const onPlaceSelected = (location) => {
        setChargedPlaces(false);
        setPlaces([]);

        if (!window.google || !window.google.maps || location == undefined) {
            return;
        }

        const request = {
            location: location,
            radius: 4 * 1000,
            type: "veterinary_care"
        };

        searchAndFetchPlaceDetails(request)
            .then(results => {
                setPlaces(results);
                setChargedPlaces(true);
            })
            .catch(error => {
                console.error(error);
                setPlaces([]);
                setChargedPlaces(true);
            });
    };

    /**
     * Realiza una búsqueda de lugares cercanos y obtiene sus detalles.
     *
     * Params: request (object) - El objeto de solicitud de búsqueda de lugares.
     * Return: Promise - Una promesa que resuelve con los detalles de los lugares encontrados o rechaza con un error.
     */
    const searchAndFetchPlaceDetails = (request) => {
        return new Promise((resolve, reject) => {
            const service = new window.google.maps.places.PlacesService(document.createElement('div'));

            service.nearbySearch(request, (results, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    const promises = [];

                    results.forEach(result => {
                        promises.push(getPlaceDetails(result.place_id, service));
                    });
                    Promise.all(promises)
                        .then(details => {
                            results.forEach((result, index) => {
                                result.info = details[index];
                            });

                            resolve(results);
                        })
                        .catch(error => {
                            reject(error);
                        });
                } else {
                    reject(new Error("Error en la búsqueda de lugares"));
                }
            });
        });
    };

    /**
     * Obtiene los detalles de un lugar dado su ID.
     *
     * Params: placeId (string) - El ID del lugar.
     *         service (object) - El servicio de Google Places.
     * Return: Promise - Una promesa que resuelve con los detalles del lugar o rechaza con un error.
     */
    const getPlaceDetails = (placeId, service) => {
        return new Promise((resolve, reject) => {
            const requestPlace = {
                placeId: placeId
            };

            service.getDetails(requestPlace, (details, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    resolve(details);
                } else {
                    reject(new Error("Error obteniendo detalles del lugar"));
                }
            });
        });
    };

    /**
     * Maneja el evento de cambio en los lugares seleccionados.
     * Busca la ubicación ingresada y llama a la función onPlaceSelected con la ubicación encontrada.
     */
    const handlePlacesChanged = () => {
        const searchText = document.getElementById('search-box').value;

        if (!window.google || !window.google.maps) {
            console.error('Google Maps API no está disponible.');
            return;
        }

        let location;
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: searchText }, (results, status) => {
            if (status === 'OK' && results && results.length > 0) {
                location = {
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng()
                };

                setCurrentLocation(location);
                onPlaceSelected(location);
            } else {
                console.error('No se pudo encontrar la ubicación.');
            }
        });
    };

    /**
     * Ejecuta efecto cuando la ubicación actual cambia y no ha sido vista previamente.
     * Llama a onPlaceSelected con la ubicación actual y actualiza el estado de la ubicación vista.
     */
    useEffect(() => {
        if (currentLocation && !viewed) {
            onPlaceSelected(currentLocation);
            setYourCurrentLocation(currentLocation);
            setViewed(true);
        }
    }, [currentLocation]);

    const customMarkerIconOptions = {
        url: customMarker,
        scaledSize: new window.google.maps.Size(40, 40),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(20, 40)
    };

    /**
     * Obtiene la hora de cierre del lugar basada en el texto proporcionado.
     *
     * Params: textPlaces (array) - Texto que contiene los horarios de apertura y cierre para cada día de la semana.
     * Return: (string) - La hora de cierre del lugar.
     */
    const getCloseHour = (textPlaces) => {
        const fecha = new Date();
        const day = fecha.getDay();
        let closeHour = "Cierra a las ";
        let dias = ["lunes", "martes", "miércoles", "jueves", "viernes", "sabado", "domingo"]

        if (textPlaces[day] == dias[day] + ": Abierto 24 horas") {
            closeHour = "Abierto las 24 horas";
        } else {
            for (let i = 0; i < 5; i++) {
                closeHour += textPlaces[day][textPlaces[day].length - 5 + i];
            }
        }

        return closeHour;
    }

    /**
     * Desplaza el contenedor hacia el elemento específico.
     *
     * Params: index (number) - Índice del elemento al que se desplazará el contenedor.
     * Return: Ninguno.
     */
    function scrollToElement(index) {
        setClickedMarker(index);
        const container = containerRef.current;
        var elements = document.querySelectorAll('.veterinary-card');
        if (elements[index]) {
            let targetScroll = elements[index].offsetLeft - container.offsetLeft - ((window.innerWidth - 300) / 2);
            container.scrollTo({
                left: targetScroll,
                behavior: 'smooth'
            });
        }
    }

    /**
     * Actualiza la ubicación actual cuando se abre una ventana de información si coincide con el marcador clicado.
     */
    useEffect(() => {
        if (infoWindowOpen == clickedMarker) {
            if (places.length > 0) {
                setCurrentLocation(places[infoWindowOpen].geometry.location);
            }
        }
    }, [infoWindowOpen]);

    return (
        <>
            <StandaloneSearchBox
                onLoad={(searchBox) => {
                    searchBox.addListener("places_changed", () => {
                        handlePlacesChanged(searchBox);
                    });
                }}
            >
                <div className='search-mapa search-container'>
                    <div className="group-search">
                        <input className="input-search" id="search-box" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} type="search" placeholder="¿Dónde necesitas tu veterinario?" />
                        <Icon icon="tabler:search" className="icon-search" />
                    </div>
                </div>
            </StandaloneSearchBox>
            {yourCurrentLocation && (
                <Marker
                    position={yourCurrentLocation}
                    icon={customMarkerIconOptions}
                >
                </Marker>
            )}

            {places.map((place, index) => (
                <Marker
                    key={`place_${index}`}
                    position={{ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() }}
                    title={place.name}
                    animation={index == infoWindowOpen ? window.google.maps.Animation.BOUNCE : null}
                    onClick={() => { setInfoWindowOpen(index); scrollToElement(index); }}
                />
            ))}
            <div className='veterinary-cards-container' ref={containerRef}>
                {chargedPlaces && places.map((place, index) => (
                    <div className='veterinary-card' key={index}>
                        <div className='image-card'>
                            {place.photos && place.photos.length > 0 && (
                                <img src={place.photos[0].getUrl()} alt="Fotografía del lugar" />
                            )}
                        </div>
                        <div className='text-card'>
                            <h2>{place.name}</h2>
                            <div>
                                {place.info.current_opening_hours && place.info.current_opening_hours?.open_now ? (
                                    <p className='green'>Abierto</p>
                                ) : (
                                    <p className='red'>Cerrado</p>
                                )}
                                {place.info.current_opening_hours && place.info.current_opening_hours?.open_now && place.info.opening_hours && place.info.opening_hours?.weekday_text && (
                                    <p className='text'>{getCloseHour(place.info.opening_hours.weekday_text)}</p>
                                )}
                            </div>
                            <div className='ubication'>
                                <div className="icon-container"><Icon icon="tabler:map-pin-filled" className="icon-ubi" /></div><p>{place.info.vicinity}</p>
                            </div>
                        </div>
                        {place.info?.formatted_phone_number && <a href={`tel:${place.info.formatted_phone_number}`} className='call-button-card call-button'><Icon icon="tabler:phone" className='icon' /></a>}
                    </div>
                ))}
            </div >
        </>
    );
}
