import React, { useState, useEffect, useRef } from "react";

export default function TimePicker({ value, onChange }) {
    const [isOpen, setIsOpen] = useState(false);
    const [hour, setHour] = useState("00");
    const [minute, setMinute] = useState("00");
    const [meridiem, setMeridiem] = useState("am");
    const timePickerRef = useRef(null);

    /**
     * Establece un evento de escucha para cerrar el selector de hora cuando se hace clic fuera de él.
     */
    useEffect(() => {
        if (value == "") {
            onChange("00:00 am");
        }
        function handleClickOutside(event) {
            if (timePickerRef.current && !timePickerRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [timePickerRef]);

    /**
     * Alterna la visibilidad del selector de hora.
     */
    const togglePicker = () => {
        setIsOpen(!isOpen);
    };

    /**
     * Maneja el cambio de la hora seleccionada.
     *
     * Params: e (evento) - El evento de cambio de hora.
     * Return: Ninguno.
     */
    const handleHourChange = (e) => {
        setHour(e.target.value);
        onChange(`${e.target.value}:${minute} ${meridiem}`);
    };

    /**
     * Maneja el cambio de los minutos seleccionados.
     *
     * Params: e (evento) - El evento de cambio de minutos.
     * Return: Ninguno.
     */
    const handleMinuteChange = (e) => {
        setMinute(e.target.value);
        onChange(`${hour}:${e.target.value} ${meridiem}`);
    };

    /**
     * Maneja el cambio de la parte meridiana (AM/PM).
     *
     * Params: e (evento) - El evento de cambio de la parte meridiana.
     * Return: Ninguno.
     */
    const handleMeridiemChange = (e) => {
        setMeridiem(e.target.value);
        onChange(`${hour}:${minute} ${e.target.value}`);
    };

    /**
     * Obtiene las partes de la hora actual del valor de entrada.
     *
     * Params: Ninguno.
     * Return: Objeto con las partes de la hora actual (hora, minutos, meridiano).
     */
    const getTimeParts = () => {
        const pattern = /^(\d+):(\d+) (am|pm)$/;
        const matches = value.match(pattern);
        if (matches || matches != null) {
            const [h, m, md] = matches;
            return { hour: h, minute: m, meridiem: md };
        }
        return { hour: "00", minute: "00", meridiem: "am" };
    };

    const { hour: initialHour, minute: initialMinute, meridiem: initialMeridiem } = getTimeParts();

    return (
        <div style={{ position: "relative", display: "inline-block" }}>
            <input
                type="text"
                className="time-pickable"
                value={value}
                readOnly
                onFocus={togglePicker}
            />
            {isOpen && (
                <div className="time-picker" ref={timePickerRef}>
                    <select
                        className="time-picker__select"
                        value={hour || initialHour}
                        onChange={handleHourChange}
                    >
                        {[...Array(12).keys()].map((number) => (
                            <option key={number} value={String(number + 1).padStart(2, "0")}>
                                {String(number + 1).padStart(2, "0")}
                            </option>
                        ))}
                    </select>
                    :
                    <select
                        className="time-picker__select"
                        value={minute || initialMinute}
                        onChange={handleMinuteChange}
                    >
                        {[...Array(12).keys()].map((number) => (
                            <option key={number * 5} value={String(number * 5).padStart(2, "0")}>
                                {String(number * 5).padStart(2, "0")}
                            </option>
                        ))}
                    </select>
                    <select
                        className="time-picker__select"
                        value={meridiem || initialMeridiem}
                        onChange={handleMeridiemChange}
                    >
                        <option value="am">am</option>
                        <option value="pm">pm</option>
                    </select>
                </div>
            )}
        </div>
    );
}
