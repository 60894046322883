import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LoadScript } from '@react-google-maps/api';
import App from './pages/App';
import Login from './pages/Login';
import Register from './pages/Register';
import Profile from './pages/Profile';
import OtherProfile from './pages/OtherProfile';
import AddPet from './pages/AddPet';
import EditPet from './pages/EditPet';
import Cards from './pages/Cards';
import Dashboard from './pages/Dashboard';
import ForgotPassword from './pages/ForgotPassword';
import EditService from './pages/EditService';
import EmergencyPage from './pages/EmergencyPage';
import Services from './pages/Services';
import MyServices from './pages/MyServices';
import ContractInfo from './pages/ContractInfo';
import Contract from './pages/Contract';
import Notifications from './pages/Notifications';
import ResetPassword from './pages/ResetPassword';
import PetProfile from './pages/PetProfile';
import AddService from './pages/AddService';
import PaymentHistory from './pages/PaymentHistory';
import CalendarPage from './pages/CalendarPage';
import VerifyEmail from './pages/VerifyEmail';
import AdminConfig from './pages/AdminConfig';
import AdminNotifications from './pages/AdminNotifications';
import { SocketProvider } from "./services/socket";

const libraries = ['places'];
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <SocketProvider>
        <LoadScript
          googleMapsApiKey="AIzaSyDNEHdirFw64LeGdgUun_3MNCat-JdpJ_w"
          libraries={libraries}
        >
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token/:email" element={<ResetPassword />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/verify-email/:tokenVerify" element={<VerifyEmail />} />

            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/other-profile" element={<OtherProfile />} />
            <Route path="/emergency-page" element={<EmergencyPage />} />
            <Route path="/calendar" element={<CalendarPage />} />
            <Route path='/notifications' element={<Notifications />} />

            <Route path='/admin' element={<AdminConfig />} />
            <Route path='/admin-notifications' element={<AdminNotifications />} />

            <Route path="/add-pet" element={<AddPet />} />
            <Route path="/edit-pet" element={<EditPet />} />
            <Route path="/pets" element={<PetProfile />} />

            <Route path="/services" element={<Services />} />
            <Route path="/my-services" element={<MyServices />} />
            <Route path="/add-service" element={<AddService />} />
            <Route path="/edit-service" element={<EditService />} />
            <Route path="/cards" element={<Cards />} />

            <Route path='/contract-info' element={<ContractInfo />} />
            <Route path='/contract' element={<Contract />} />
            <Route path="/payment-history" element={<PaymentHistory />} />
          </Routes>
        </LoadScript>
      </SocketProvider>
    </BrowserRouter>
  </React.StrictMode >
);
