import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import './css/StarRating.css';

export default function StarRating({ rating, setRating, read }) {
    const [hover, setHover] = useState(null);

    return (
        !read ? (
            <div className='stars' >
                {[...Array(5)].map((star, i) => {
                    const ratingValue = i + 1;

                    return (
                        <label key={i}>
                            <Icon
                                icon="tabler:star-filled"
                                className="star"
                                color={ratingValue <= (hover || rating) ? "#ffc107" : "#e4e5e9"}
                                onClick={() => setRating(ratingValue)}
                                onMouseEnter={() => setHover(ratingValue)}
                                onMouseLeave={() => setHover(null)}
                            />
                        </label>
                    );
                })}
            </div >
        ) : (
            <div className='stars-read' >
                {[...Array(5)].map((star, i) => {
                    const ratingValue = i + 1
                    return (
                        <label key={i}>
                            <Icon
                                icon="tabler:star-filled"
                                className="star-read"
                                color={ratingValue <= rating ? "#ffc107" : "#e4e5e9"}
                            />
                        </label>
                    );
                })}
            </div >
        )
    );
};