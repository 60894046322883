import React, { useState, useEffect } from 'react';
import './css/Profile.css';
import './css/GeneralStyles.css';
import StarRating from '../components/StarRating';
import RatingModal from '../components/RatingModal';
import { Icon } from '@iconify/react';
import { useNavigate, useLocation } from 'react-router-dom';

export default function OtherProfile() {
    const token = localStorage.getItem("cookie_token");
    const navigate = useNavigate();
    const location = useLocation();
    const idUser = location.state ? location.state.id : navigate("/dashboard");
    const [userData, setUserData] = useState(null);
    const [focus, setFocus] = useState(0);
    const [showModal, setShowModal] = useState(false);

    /**
     * Busca los datos del usuario al montar el componente.
     */
    useEffect(() => {
        getUserData();
    }, []);

    /**
     * Obtiene los datos de usuario del backend.
     *
     * Params:
     *   idUser (string): Identificador único del usuario.
     * Return:
     *   Ninguno. (La función actualiza el estado con los datos del usuario)
     */
    const getUserData = () => {
        setUserData(null);
        fetch(process.env.REACT_APP_API_URL + "/api/user-profile/" + idUser, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setUserData(data);
            });
    }

    /**
     * Elimina la calificación actual del usuario.
     */
    const deleteRating = () => {
        fetch(process.env.REACT_APP_API_URL + "/api/delete-rating/" + userData.your_rating.id, {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.message == 1) {
                    getUserData();
                }
            });
    }

    /**
     * Cambia el índice del elemento con el foco.
     *
     * Params:
     *   index (number): Indice del elemento que recibirá el foco.
     */
    const handleFocusButton = (index) => {
        setFocus(index);
    }

    return (
        <div className="container">
            <div className="content-wo-footer">
                <div className="header-bar blue-bar">
                    <button className="button-bar blue-bar-title" size="icon" variant="ghost" onClick={() => navigate("/dashboard")}>
                        <Icon className='icon-bar back-icon-bar' icon="material-symbols:arrow-back-ios" />
                    </button>
                    <h1 className="title-bar blue-bar-title">Profile</h1>
                    <button className="disabled-button" disabled></button>
                </div>
                {userData ? (
                    <>
                        <div className="profile-element other-profile">
                            <div className="image-profile-element fade-in-animation">
                                <>
                                    <img
                                        alt="Avatar"
                                        className="image-profile fade-in-animation"
                                        src={userData.userData.avatar ? userData.userData.avatar : "https://source.unsplash.com/random"}
                                    />
                                </>
                            </div>
                            <div className="name-container fade-in-animation">
                                <h1>{userData.userData.name}</h1>
                            </div>
                            <div className='nav-bar'>
                                <div className='nav-bar-elements little-nav'>
                                    <button className={'nav-bar-element ' + (focus == 0 && "focus")} onClick={() => handleFocusButton(0)}>Servicios</button>
                                    <button className={'nav-bar-element ' + (focus == 1 && "focus")} onClick={() => handleFocusButton(1)}>Valoraciones</button>
                                </div>
                            </div>
                        </div>
                        {focus == 0 ? (
                            userData.services == null ? (
                                <h2>Este usuario tiene ningún servicio activo</h2>
                            ) : (
                                <div className='list-services'>
                                    {userData.services.map((service, index) => {
                                        return (
                                            <div key={index} className='fade-in-animation'>
                                                <div className='service' onClick={() => navigate("/contract-info", { state: { service: service } })}>
                                                    <div className='img-service-container'><img src={userData.userData.avatar} className="img-profile" /></div>
                                                    <div className='service-info'>
                                                        <div>
                                                            <p className='title-user'>{service.category}</p>
                                                            <p className='job-user'>{service.municipality}</p>
                                                            <p className='job-user'>{service.animal_type}</p>
                                                        </div>
                                                    </div>
                                                    <div className='go-button'>
                                                        <Icon icon="material-symbols:arrow-forward-ios" />
                                                    </div>
                                                </div>
                                                <div className='line-container'><div className='line'></div></div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        ) : (
                            userData.ratings == null ? (
                                <h2>Este usuario tiene ningún servicio activo</h2>
                            ) : (
                                <div className='list-services'>
                                    {userData.your_rating && (
                                        <>
                                            <div className='fade-in-animation'>
                                                <div className='service service-rating'>
                                                    <div className='img-service-container'><img src={userData.your_rating.avatar} className="img-profile" /></div>
                                                    <div className='service-info'>
                                                        <div>
                                                            <p className='title-user'>{userData.your_rating.name}</p>
                                                            <StarRating rating={userData.your_rating.rating} read={true} />
                                                            <p className='job-user job-user-ratings'>{userData.your_rating.description}</p>
                                                        </div>
                                                    </div>
                                                    <div className='edit-rating-buttons'>
                                                        <button className='edit' onClick={() => setShowModal(true)}><Icon icon="tabler:edit" ></Icon></button>
                                                        <button className='delete' onClick={() => deleteRating()}><Icon icon="tabler:trash"></Icon></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='line-container'><div className='line'></div></div>
                                        </>
                                    )}
                                    {userData.ratings.map((rating, index) => {
                                        return (
                                            <div key={index} className='fade-in-animation'>
                                                <div className='service service-rating'>
                                                    <div className='img-service-container'><img src={rating.avatar} className="img-profile" /></div>
                                                    <div className='service-info'>
                                                        <div>
                                                            <p className='title-user'>{rating.name}</p>
                                                            <StarRating rating={rating.rating} read={true} />
                                                            <p className='job-user'>{rating.description}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='line-container'><div className='line'></div></div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        )}
                    </>
                ) : (
                    <div className="profile-element">
                        <div className="image-profile-element">
                            <div className="image-profile skeleton"></div>
                        </div>
                        <div className="name-container">
                            <div className='skeleton h1-skeleton'></div>
                        </div>
                    </div>
                )}
                {showModal && (
                    <RatingModal setShowModal={() => setShowModal()} ratingData={userData.your_rating} refresh={() => getUserData()} />
                )}
            </div>
        </div>
    );
};