import React, { useEffect, useState, useRef } from 'react';
import "./css/PetProfile.css";
import "./css/GeneralStyles.css";
import Footer from '../components/FooterMenu';
import Loader from '../components/Loader';
import Slider from '../components/Slider';
import AddPetEmpty from '../components/AddPetEmpty';
import { Icon } from '@iconify/react';
import { useNavigate, useLocation } from 'react-router-dom';

export default function PetProfile() {
    const navigate = useNavigate();
    const token = localStorage.getItem("cookie_token");
    const [pets, setPets] = useState([]);
    const [loadContent, setLoadContent] = useState(false);
    const containerRef = useRef(null);
    const [currentPetIndex, setCurrentPetIndex] = useState(0);
    const [startX, setStartX] = useState(0);

    /**
     * Realiza la carga inicial de las mascotas al montarse el componente.
     */
    useEffect(() => {
        getPets();
    }, []);

    /**
     * Obtiene la lista de mascotas del usuario logueado.
     *
     * En caso de éxito, se establece el array de mascotas en el estado usando `setPets(data.pets)`.
     * También se establece un indicador de carga en `setLoadContent(true)`, posiblemente para mostrar 
     * un mensaje de carga o detener un proceso de carga visual.
     */
    const getPets = () => {
        fetch(process.env.REACT_APP_API_URL + "/api/my-pets", {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setPets(data.pets);
                setLoadContent(true);
            });
    }

    /**
     * Registra la coordenada X inicial del touch del usuario en el evento touchstart.
     *
     * Params:
     *   event (object): Objeto del evento touchstart.
     */
    const handleTouchStart = (event) => {
        setStartX(event.touches[0].clientX);
    };

    /**
     * Maneja el evento touchend para detectar swipes horizontales.
     *
     * Params:
     *   event (object): Objeto del evento touchend.
     * 
     * - Si el deltaX (desplazamiento horizontal) es mayor a 50, se llama a la función prevPet, simulando un swipe hacia la izquierda.
     * - Si el deltaX es menor a -50, se llama a la función nextPet, simulando un swipe hacia la derecha.
     */
    const handleTouchEnd = (event) => {
        const endX = event.changedTouches[0].clientX;
        const deltaX = endX - startX;
        if (deltaX > 50) {
            prevPet();
        } else if (deltaX < -50) {
            nextPet();
        }
    };

    /**
     * Navega al siguiente elemento en la lista de mascotas.
     *
     * Calcula el índice del siguiente elemento en la lista pets considerando el índice actual (currentPetIndex) 
     * y la longitud de la lista. Utiliza el módulo (%) para manejar casos fuera del límite (ej: si currentPetIndex 
     * está en el último elemento, al sumar 1 y aplicar el módulo, vuelve al primer elemento).
     * Actualiza el estado con el nuevo índice mediante setCurrentPetIndex(nextIndex).
     */
    const nextPet = () => {
        const nextIndex = (currentPetIndex + 1) % pets.length;
        setCurrentPetIndex(nextIndex);
    };

    /**
     * Navega al elemento anterior en la lista de mascotas.
     *
     * Calcula el índice del elemento anterior en la lista pets de forma similar a nextPet, pero restando 1 
     * en lugar de sumar. El uso del módulo (%) garantiza que el índice se mantenga dentro de los límites de la lista.
     * Actualiza el estado con el nuevo índice mediante setCurrentPetIndex(prevIndex).
     */
    const prevPet = () => {
        const prevIndex = (currentPetIndex - 1 + pets.length) % pets.length;
        setCurrentPetIndex(prevIndex);
    };

    return (
        <div className="container">
            <div className="alice-content content-w-footer"
                ref={containerRef}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}>
                {loadContent && pets.length != 0 ? (
                    <div className='fade-in-animation'>
                        <button className="pet-button back-pet-button" variant="secondary" onClick={() => navigate("/profile")}>
                            <Icon className='icon-pet-button' icon="material-symbols:arrow-back-ios" />
                        </button>
                        <button className="add-pet-button" variant="secondary" onClick={() => navigate("/add-pet")}>
                            <Icon icon="tabler:plus" />
                        </button>
                        {pets[currentPetIndex].image != null ? (
                            <img src={pets[currentPetIndex].image} alt="Slide" className="image" />
                        ) : (
                            <img src="https://picsum.photos/500" alt="Slide" className="image" />
                        )}
                        <div className="container-circles-slider">
                            {pets.map((pet, index) => {
                                return (
                                    <div className={'circle-slider ' + (index == currentPetIndex && "selected-circle")} key={index}></div>
                                )
                            })}
                        </div>
                        <div className="categories categories-pet">
                            <div className="header-categories">
                                <div>
                                    <h1 className="pet-title">{pets[currentPetIndex].name}</h1>
                                    <p className="">Staffy</p>
                                </div>
                                <button className="pet-button edit-pet-button" variant="secondary" onClick={() => navigate("/edit-pet", { state: { pet: pets[currentPetIndex] } })}>
                                    Editar
                                </button>
                            </div>
                            <div className="info-items">
                                {pets[currentPetIndex].gender == "Male" ? (
                                    <div className="item">
                                        <span className="category blue-category"><Icon icon="tabler:gender-female" className="white-icon" /></span>
                                        <span className="simple-text-category">Macho</span>
                                    </div>
                                ) : (
                                    <div className="item">
                                        <span className="category blue-category"><Icon icon="tabler:gender-female" className="white-icon" /></span>
                                        <span className="simple-text-category">Hembra</span>
                                    </div>
                                )}
                                <div className="item">
                                    <span className="category blue-category"><Icon icon="tabler:clock" className="white-icon" /></span>
                                    <span className="simple-text-category">{pets[currentPetIndex].age} Años</span>
                                </div>
                                <div className="item">
                                    <span className="category blue-category"><Icon icon="tabler:home-filled" className="white-icon" /></span>
                                    {pets[currentPetIndex].reactive == 1 ? (
                                        <span className="simple-text-category">Reactiva</span>
                                    ) : (
                                        <span className="simple-text-category">No reactiva</span>
                                    )}
                                </div>
                                <div className="item">
                                    <span className="category blue-category"><Icon icon="material-symbols:dining" className="white-icon" /></span>
                                    <span className="simple-text-category">Alergias</span>
                                </div>
                            </div>
                            {pets[currentPetIndex].description != "" && (
                                <p className="pet-description">
                                    {pets[currentPetIndex].description}
                                </p>
                            )}
                            <p className='info-slide'>&#8592;  Desliza para cambiar de mascota  &#8594;</p>
                        </div>
                    </div>
                ) : loadContent && pets.length == 0 ? (
                    <AddPetEmpty />
                ) : (
                    <Loader />
                )}
            </div>
            <Footer />
        </div >

    )
}