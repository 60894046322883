import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import "./css/GeneralStyles.css";
import "./css/Login.css";
import { Icon } from '@iconify/react';
import Logo from '../images/logo.jpeg';
import LoginApiButtons from '../components/LoginApiButtons';

export default function Login() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [eye, setEye] = useState(true);

    /**
     * Inicia sesión del usuario.
     * 
     * Params: e (Event) - El evento del formulario.
     * Return: Ninguno.
     */
    const loginUser = (e) => {
        localStorage.clear();
        e.preventDefault();

        var formDataUser = new FormData();
        formDataUser.append("email", email);
        formDataUser.append("password", password);

        fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
            method: "POST",
            body: formDataUser
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                if (data !== false) {
                    localStorage.setItem("id_user", data.id);
                    localStorage.setItem("cookie_token", data.token);
                    localStorage.setItem("fullName", data.user.fullName);
                    localStorage.setItem("avatar", data.user.avatar);
                    navigate('/dashboard');
                } else {
                    setErrorMessage("El usuario no existe o la contraseña es incorrecta");
                }
            });
    }

    return (
        <div className='container'>
            <div className="session">
                <form onSubmit={loginUser} className='content'>
                    <div className='log-in-logo-container'>
                        <img className='log-in-logo' src={Logo} alt="Logo" />
                    </div>
                    <h4 className='log-in-title'>Welcome back!</h4>
                    <div className='input-container'>
                        <input
                            type="text"
                            className="input"
                            placeholder="Email"
                            id='email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {eye ? (
                            <div className='password-container'>
                                <input
                                    type="password"
                                    className="input"
                                    placeholder="Password"
                                    id='password'
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <Icon icon="tabler:eye-closed" onClick={() => setEye(!eye)} />
                            </div>
                        ) : (
                            <div className='password-container'>
                                <input
                                    type="text"
                                    className="input"
                                    placeholder="Password"
                                    id='password'
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <Icon icon="tabler:eye" onClick={() => setEye(!eye)} />
                            </div>
                        )}
                    </div>
                    <p className="error-message">{errorMessage}</p>
                    <div className='log-in-button-container'>
                        <button type="submit" className="login-button main-button" onClick={loginUser}>Iniciar sesión</button>
                    </div>
                </form>
                <div className='or-container'>
                    <div className="barra-log-in"></div>
                    <p className='or-text'>or</p>
                    <div className="barra-log-in"></div>
                </div>
                <div className='other-options'>
                    <div>
                        <LoginApiButtons />
                        <div className='log-in-link-buttons-container'>
                            <div className='login-links-container'>
                                <div className='link-container'>
                                    <p className='link-text'>No estas registrado?</p><Link className='link' to="/register">Registrate aquí</Link>
                                </div>
                                <div className='link-container forgot-link'>
                                    <p className='link-text'></p><Link to="/forgot-password" className='link'>Olvidé mi contraseña</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
