import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthCookie from '../components/AuthCookie';
import '../pages/css/Profile.css';
import '../pages/css/GeneralStyles.css';

export default function AddPet() {
    const navigate = useNavigate();
    const token = localStorage.getItem("cookie_token");
    const [name, setName] = useState('');
    const [gender, setGender] = useState('');
    const [type, setType] = useState('');
    const [age, setAge] = useState('');
    const [weight, setWeight] = useState('');
    const [breed, setBreed] = useState('');
    const [breedSpecification, setBreedSpecification] = useState('');
    const [reactive, setReactive] = useState(false);
    const [reactiveDescription, setReactiveDescription] = useState('');
    const [microchipped, setMicrochipped] = useState(true);
    const [sterilizationStatus, setSterilizationStatus] = useState('');
    const [needsOutside, setNeedsOutside] = useState(true);
    const [getsAlongWithChildren, setGetsAlongWithChildren] = useState(true);
    const [getsAlongWithDogs, setGetsAlongWithDogs] = useState(true);
    const [getsAlongWithCats, setGetsAlongWithCats] = useState(true);
    const [adoptionDate, setAdoptionDate] = useState(1);
    const [observations, setObservations] = useState('');
    const [careInformation, setCareInformation] = useState('');
    const [bathroomFrequency, setBathroomFrequency] = useState('');
    const [energyLevel, setEnergyLevel] = useState('');
    const [feedingRoutine, setFeedingRoutine] = useState('');
    const [canStayAlone, setCanStayAlone] = useState('');
    const [medicationInfo, setMedicationInfo] = useState('');
    const [veterinaryInfo, setVeterinaryInfo] = useState('');
    const [petInsuranceProvider, setPetInsuranceProvider] = useState('');
    const [image, setImage] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);

    /**
     * Maneja el envío del formulario para agregar una mascota.
     * 
     * Params: e (Event) - El evento del formulario.
     * Return: Ninguno.
     */
    const handleSubmit = async (e) => {
        e.preventDefault();

        uploadImage(selectedImage);

        const formData = new FormData();
        formData.append('name', name);
        formData.append('gender', gender);
        formData.append('type', type);
        formData.append('age', age);
        formData.append('weight', weight);
        formData.append('breed', breed);
        formData.append('breedSpecification', breedSpecification);
        formData.append('reactive', reactive ? '1' : '0');
        formData.append('reactiveDescription', reactiveDescription);
        formData.append('microchipped', microchipped ? '1' : '0');
        formData.append('sterilizationStatus', sterilizationStatus);
        formData.append('needsOutside', needsOutside ? '1' : '0');
        formData.append('getsAlongWithChildren', getsAlongWithChildren ? '1' : '0');
        formData.append('getsAlongWithDogs', getsAlongWithDogs ? '1' : '0');
        formData.append('getsAlongWithCats', getsAlongWithCats ? '1' : '0');
        formData.append('adoptionDate', adoptionDate);
        formData.append('observations', observations);
        formData.append('careInformation', careInformation);
        formData.append('bathroomFrequency', bathroomFrequency);
        formData.append('energyLevel', energyLevel);
        formData.append('feedingRoutine', feedingRoutine);
        formData.append('canStayAlone', canStayAlone);
        formData.append('medicationInfo', medicationInfo);
        formData.append('veterinaryInfo', veterinaryInfo);
        formData.append('petInsuranceProvider', petInsuranceProvider);
        formData.append('image', image);

        var jsonObject = {};
        formData.forEach(function (value, key) {
            jsonObject[key] = value;
        });

        console.log(jsonObject);

        fetch(process.env.REACT_APP_API_URL + "/api/add-pet", {
            method: "POST",
            body: formData,
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.error == false) {
                    console.log(data);
                    navigate("/profile");
                }
            });
    };

    /**
     * Maneja el cambio de imagen para la mascota.
     * 
     * Params: event (Event) - El evento del cambio de imagen.
     * Return: Ninguno.
     */
    const handleImageChange = (event) => {
        setSelectedImage(event.target.files[0]);
    };

    /**
     * Sube una imagen al servidor.
     * 
     * Params: image (File) - La imagen a subir.
     * Return: Ninguno.
     */
    const uploadImage = async (image) => {
        if (image != null) {
            const formData = new FormData();
            formData.append('image', image);

            try {
                fetch(process.env.REACT_APP_API_URL + "/api/upload-image", {
                    method: "POST",
                    body: formData,
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + token,
                    },
                })
                    .then((response) => response.json())
                    .then((data) => {
                        console.log(data);
                        setImage(data.imageUrl);
                    });
            } catch (error) {
                console.error('Error de red:', error);
            }
        }
    };

    return (
        <div className="container">
            <AuthCookie />
            <div className='session'>
                <form onSubmit={handleSubmit} className="form-container-addPet">
                    <h4 className="log-in-title">Añadir Mascota</h4>
                    <div className="form-column">
                        <div className="input-form">
                            <label>Nombre: *</label>
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="input"
                            />
                        </div>
                        <div className="input-form">
                            <label>Género: *</label>
                            <select
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                                className="input"
                            >
                                <option value="" disabled>Selecciona un género</option>
                                <option value="Male">Macho</option>
                                <option value="Female">Hembra</option>
                            </select>
                        </div>
                        <div className="input-form">
                            <label>Tipo: *</label>
                            <select
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                                className="input"
                            >
                                <option value="" disabled>Selecciona un tipo</option>
                                <option value="Dog">Perro</option>
                                <option value="Cat">Gato</option>
                                <option value="Turtle">Tortuga</option>
                                <option value="Rabbit">Conejo</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-column">
                        <div className="input-form">
                            <label>Edad: *</label>
                            <input
                                type="number"
                                value={age}
                                onChange={(e) => setAge(e.target.value)}
                                className="input"
                            />
                        </div>
                        <div className="input-form">
                            <label>Peso: *</label>
                            <input
                                type="number"
                                value={weight}
                                onChange={(e) => setWeight(e.target.value)}
                                className="input"
                            />
                        </div>
                        <div className="input-form">
                            <label>Raza: *</label>
                            <select
                                value={breed}
                                onChange={(e) => setBreed(e.target.value)}
                                className="input"
                            >
                                <option value="" disabled>Selecciona una raza</option>
                                <option value="Breed">Raza</option>
                                <option value="Mixed">Mestizo</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-column">
                        <div className="input-form">
                            <label>Especificación de Raza: *</label>
                            <input
                                type="text"
                                value={breedSpecification}
                                onChange={(e) => setBreedSpecification(e.target.value)}
                                className="input"
                            />
                        </div>
                        <div className="input-form">
                            <label>Reactiva:</label>
                            <input
                                type="checkbox"
                                checked={reactive}
                                onChange={(e) => setReactive(e.target.checked)}
                                className="input"
                            />
                        </div>
                        {reactive && (
                            <div className="input-form">
                                <label>Descripción Reactiva:</label>
                                <textarea
                                    value={reactiveDescription}
                                    onChange={(e) => setReactiveDescription(e.target.value)}
                                    className="input"
                                />
                            </div>
                        )}
                    </div>
                    <div className="form-column">
                        <div className="input-form">
                            <label>Microchip:</label>
                            <input
                                type="checkbox"
                                checked={microchipped}
                                onChange={(e) => setMicrochipped(e.target.checked)}
                                className="input"
                            />
                        </div>
                        <div className="input-form">
                            <label>Estado de Esterilización:</label>
                            <select
                                value={sterilizationStatus}
                                onChange={(e) => setSterilizationStatus(e.target.value)}
                                className="input"
                            >
                                <option value="" disabled>Selecciona un estado de esterilización</option>
                                <option value="Castrated">Castrado</option>
                                <option value="Sterilized">Esterilizado</option>
                                <option value="None">Nada</option>
                            </select>
                        </div>
                        <div className="input-form">
                            <label>Hace sus necesidades fuera de casa:</label>
                            <input
                                type="checkbox"
                                checked={needsOutside}
                                onChange={(e) => setNeedsOutside(e.target.checked)}
                                className="input"
                            />
                        </div>
                    </div>
                    <div className="form-column">
                        <div className="input-form">
                            <label>Se Lleva Bien con Niños:</label>
                            <input
                                type="checkbox"
                                checked={getsAlongWithChildren}
                                onChange={(e) => setGetsAlongWithChildren(e.target.checked)}
                                className="input"
                            />
                        </div>
                        <div className="input-form">
                            <label>Se Lleva Bien con Perros:</label>
                            <input
                                type="checkbox"
                                checked={getsAlongWithDogs}
                                onChange={(e) => setGetsAlongWithDogs(e.target.checked)}
                                className="input"
                            />
                        </div>
                        <div className="input-form">
                            <label>Se Lleva Bien con Gatos:</label>
                            <input
                                type="checkbox"
                                checked={getsAlongWithCats}
                                onChange={(e) => setGetsAlongWithCats(e.target.checked)}
                                className="input"
                            />
                        </div>
                    </div>
                    <div className="form-column">
                        <div className="input-form">
                            <label>Fecha de Adopción:</label>
                            <input
                                type="date"
                                value={adoptionDate}
                                onChange={(e) => setAdoptionDate(e.target.value)}
                                className="input"
                            />
                        </div>
                    </div>
                    <div className="form-column">
                        <div className="input-form">
                            <label>Frecuencia de Baño:</label>
                            <input
                                type="text"
                                value={bathroomFrequency}
                                onChange={(e) => setBathroomFrequency(e.target.value)}
                                className="input"
                            />
                        </div>
                        <div className="input-form">
                            <label>Nivel de Energía:</label>
                            <select
                                value={energyLevel}
                                onChange={(e) => setEnergyLevel(e.target.value)}
                                className="input"
                            >
                                <option value="" disabled>Select energy level</option>
                                <option value="None">Nada</option>
                                <option value="Low">Baja</option>
                                <option value="Medium">Media</option>
                                <option value="High">Alta</option>

                            </select>
                        </div>
                        <div className="input-form">
                            <label>Puede Estar Solo:</label>
                            <select
                                value={canStayAlone}
                                onChange={(e) => setCanStayAlone(e.target.value)}
                                className="input"
                            >
                                <option value="" disabled>Select duration</option>
                                <option value="No">Nada</option>
                                <option value="1 hour">1 Hora</option>
                                <option value="2 hours">2 Horas</option>
                                <option value="3 hours">3 Horas</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-column">
                        <div className="input-form">
                            <label>Rutina de Alimentación:</label>
                            <input
                                type="text"
                                value={feedingRoutine}
                                onChange={(e) => setFeedingRoutine(e.target.value)}
                                className="input"
                            />
                        </div>
                        <div className="input-form">
                            <label>Información de Medicación:</label>
                            <input
                                type="text"
                                value={medicationInfo}
                                onChange={(e) => setMedicationInfo(e.target.value)}
                                className="input"
                            />
                        </div>
                        <div className="input-form">
                            <label>Información Veterinaria:</label>
                            <input
                                type="text"
                                value={veterinaryInfo}
                                onChange={(e) => setVeterinaryInfo(e.target.value)}
                                className="input"
                            />
                        </div>
                    </div>
                    <div className="form-column">
                        <div className="input-form">
                            <label>Información de Cuidado:</label>
                            <textarea
                                value={careInformation}
                                onChange={(e) => setCareInformation(e.target.value)}
                                className="input"
                            />
                        </div>
                        <div className="input-form">
                            <label>Proveedor de Seguro para Mascotas:</label>
                            <input
                                type="text"
                                value={petInsuranceProvider}
                                onChange={(e) => setPetInsuranceProvider(e.target.value)}
                                className="input"
                            />
                        </div>
                        <div className="input-form">
                            <label>Observaciones:</label>
                            <textarea
                                value={observations}
                                onChange={(e) => setObservations(e.target.value)}
                                className="input"
                            />
                        </div>
                        <div className="input-form-image">
                            <label>Sube la foto de tu mascota aquí:</label>
                            <input type="file" accept="image/*" onChange={handleImageChange} className="image-upload" />
                            {selectedImage && (
                                <img src={URL.createObjectURL(selectedImage)} alt="Preview" className="image-preview" />
                            )}
                        </div>
                    </div>
                    <div className='login-buttons-container add-mascota'>
                        <button type="submit" className=' '>Añadir Mascota</button>
                    </div>
                </form>
            </div>
        </div>
    );
}
