import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import StarRating from '../components/StarRating';
import Loader from '../components/Loader';
import './css/StarRating.css';

export default function RatingModal({ notificacion, setShowModal, getNotificaciones, ratingData, refresh }) {
    const token = localStorage.getItem("cookie_token");
    const fullName = localStorage.getItem('fullName');
    const avatar = localStorage.getItem('avatar');
    const [rating, setRating] = useState(null);
    const [description, setDescription] = useState("");
    const [hover, setHover] = useState(null);
    const [localData, setLocalData] = useState(ratingData ? ratingData : null);

    /**
     * Restablece la descripción y la calificación, y busca los datos de calificación si no se proporciona ratingData.
     */
    useEffect(() => {
        setDescription("");
        setRating(0);

        if (ratingData == null) {
            if (notificacion != null) {
                var formData = new FormData();
                formData.append('user_id', notificacion.user_id);
                formData.append('rated_user_id', notificacion.requested_user);
                fetch(process.env.REACT_APP_API_URL + "/api/search-rating", {
                    method: "POST",
                    body: formData,
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + token,
                    },
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.message == 0) {
                            setLocalData(false)
                        } else {
                            setLocalData(data);
                        }
                    });
            } else {
                setLocalData(false);
            }
        } else {
            setDescription(ratingData.description);
            setRating(ratingData.rating);
            setLocalData(false);
        }
    }, []);

    /**
     * Agrega una calificación al usuario especificado en la notificación.
     */
    const addRating = () => {
        var formData = new FormData();
        formData.append('rated_user_id', notificacion.requested_user);
        formData.append('rating', rating);
        formData.append('description', description);
        formData.append('com_id', notificacion.id);

        fetch(process.env.REACT_APP_API_URL + "/api/add-rating", {
            method: "POST",
            body: formData,
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setShowModal(false);
                if (data.message == 1) {
                    getNotificaciones();
                }
            });
    }

    /**
     * Edita la calificación del usuario.
     */
    const editRating = () => {
        var formData = new FormData();
        console.log(ratingData)
        formData.append('id', ratingData.id);
        formData.append('rating', rating);
        formData.append('description', description);

        fetch(process.env.REACT_APP_API_URL + "/api/edit-rating", {
            method: "POST",
            body: formData,
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.message == 1) {
                    setShowModal(false);
                    refresh();
                }
            });
    }

    /**
     * Establece la calificación anterior del usuario.
     */
    const setOldRating = () => {
        setDescription(localData.description);
        setRating(localData.rating);
    }

    return (
        <div className="modal-bg">
            <div className="modal rating-modal">
                {localData != null ? (
                    <div className="fade-in-animation">
                        <div className='rating-modal-user'>
                            <img src={avatar} className='rating-modal-img'></img>
                            <div className='rating-modal-user-info'>
                                <h3>{fullName}</h3>
                                <div className='rating-modal-info'>
                                    <Icon icon="tabler:info-circle" />
                                    <p>Las reseñas no son anonimas</p>
                                </div>
                            </div>
                        </div>
                        <StarRating rating={rating} setRating={setRating} read={false} />
                        <div className='rating-modal-text'>
                            <textarea value={description} onChange={(e) => setDescription(e.target.value)} placeholder='Comparte detalles de tu experiencia' />
                        </div>
                        {notificacion != null && localData != false && (
                            <>
                                <div className='rating-modal-info'>
                                    <Icon icon="tabler:info-circle" />
                                    <p>Ya has valorado a este usuario</p>
                                </div>
                                <a className='edit-old-rating' onClick={() => setOldRating()}>Editar Anterior Reseña</a>
                            </>
                        )}
                        <div className="modal-buttons">
                            <button className="cancel modal-button" onClick={() => setShowModal(false)}>Cancelar</button>
                            <button className="rating-btn modal-button" onClick={ratingData == null ? () => addRating() : () => editRating()}>{ratingData == null ? "Valorar" : "Editar"}</button>
                        </div>
                    </div>
                ) : (
                    <>
                        <Loader />
                    </>
                )}
            </div>
        </div>
    );
};