import React, { useState, useEffect } from 'react';
import './css/Profile.css';
import './css/GeneralStyles.css';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import Avatars from '../components/Avatars';
import Switch from '../components/Switch';
import Loader from '../components/Loader';
import TopServiceModal from '../components/TopServiceModal';

export default function Profile() {
    const token = localStorage.getItem("cookie_token");
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const [userData, setUserData] = useState(null);
    const [updatingAvatar, setUpdatingAvatar] = useState(false);
    const [checkedCards, setCheckedCards] = useState(localStorage.getItem("cards") ? true : false);
    const [loadingCards, setLoadingCards] = useState(true);
    const [showModalTopService, setModalShowTopService] = useState(false);

    /**
     * Carga inicial del perfil de usuario y tarjetas guardadas al montarse el componente.
     */
    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + "/api/user-profile", {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                localStorage.setItem("cards", data.userData.cards);
                setCheckedCards(data.userData.cards);
                setUserData(data.userData);
                setLoadingCards(false);
            });
    }, []);

    /**
     * Abre el modal de avatar.
     */
    const openModalAvatar = () => {
        setOpenModal(true);
    }

    /**
     * Cierra la sesión y redirige al login.
     */
    const logOut = () => {
        localStorage.clear();
        navigate("/login");
    };

    /**
     * Maneja el cambio en el estado de las tarjetas seleccionadas.
     */
    const changeCheckedCards = (checkCards) => {
        if (checkCards) {
            setModalShowTopService(true);
        } else {
            toogleCheckedCards(checkCards);
        }
    }

    /**
     * Establece el servicio superior y cierra el modal.
     *
     * Params:
     *   id (number): Identificador del servicio a establecer como superior.
     * Return: Ninguno.
     */
    const checkTopService = (id) => {
        setModalShowTopService(false);
        fetch(process.env.REACT_APP_API_URL + "/api/set-top-service/" + id, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                toogleCheckedCards(true);
            });
    }


    /**
     * Actualiza el estado de las tarjetas seleccionadas y las guarda en localStorage.
     *
     * Params:
     *   checkCards (boolean): Indica el estado de selección de las tarjetas (true: seleccionadas, false: deseleccionadas).
     * Return: Ninguno.
     */
    const toogleCheckedCards = (checkCards) => {
        fetch(process.env.REACT_APP_API_URL + "/api/toogle-cards/" + checkCards, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setTimeout(() => {
                    console.log(data);
                    setLoadingCards(false);
                    setCheckedCards(checkCards);
                    localStorage.setItem('cards', checkCards);
                }, 1000);
            });
    }

    /**
     * Cierra el modal superior y finaliza la carga.
     */
    const closeTopModal = () => {
        setModalShowTopService(false);
        setLoadingCards(false);
    }

    return (
        <div className="container">
            <div className="content-wo-footer">
                <div className="header-bar blue-bar">
                    <button className="button-bar blue-bar-title" size="icon" variant="ghost" onClick={() => navigate("/dashboard")}>
                        <Icon className='icon-bar back-icon-bar' icon="material-symbols:arrow-back-ios" />
                    </button>
                    <h1 className="title-bar blue-bar-title">Profile</h1>
                    <button className="disabled-button" disabled></button>
                </div>
                <div className="profile-element">
                    {userData ? (
                        <>
                            <div className="image-profile-element fade-in-animation">
                                {updatingAvatar ? (
                                    <div className='image-profile skeleton'></div>
                                ) : (
                                    <>
                                        <img
                                            alt="Avatar"
                                            className="image-profile fade-in-animation"
                                            src={userData.avatar ? userData.avatar : "https://source.unsplash.com/random"}
                                        />
                                        <button className="button-profile-element fade-in-animation" size="icon" variant="ghost" onClick={() => openModalAvatar()}>
                                            <Icon icon="tabler:edit" className='icon-edit' />
                                            <span className="sr-only">Edit</span>
                                        </button>
                                    </>
                                )}
                            </div>
                            <div className="name-container fade-in-animation">
                                <h1>{userData.name}</h1>
                                <p>@{userData.email}</p>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="image-profile-element">
                                <div className="image-profile skeleton"></div>
                            </div>
                            <div className="name-container">
                                <div className='skeleton h1-skeleton'></div>
                                <div className='skeleton p-skeleton'></div>
                            </div>
                        </>
                    )}
                </div>
                <div className='config-buttons-container'>
                    <div className='config-general'>
                        <div className='title-config'><h2>Configuración general</h2></div>
                        {localStorage.getItem('email_verified_at') == "null" && (
                            <button className="button-config size-not-effect" onClick={() => navigate("/verify-email")} ><div className='element-button-config'><Icon className='icon-config alert' icon="tabler:alert-triangle" /><p>Verificar Email</p></div><Icon icon="material-symbols:arrow-forward-ios" /></button>
                        )}
                        {userData && userData.role == 1 && (
                            <button className="button-config size-not-effect" onClick={() => navigate("/admin")} ><div className='element-button-config'><Icon className='icon-config' icon="tabler:settings" /><p>Admin Control</p></div><Icon icon="material-symbols:arrow-forward-ios" /></button>
                        )}
                        <button className="button-config size-not-effect">
                            <div className='element-button-config'><Icon className='icon-config' icon="material-symbols:playing-cards-rounded" /><p>Cards</p></div>
                            {loadingCards ? (
                                <div className="card-loader fade-in-animation">
                                    <Loader />
                                </div>
                            ) : (
                                <Switch checkedCards={checkedCards} setCheckedCards={setCheckedCards} changeCheckedCards={changeCheckedCards} setLoadingCards={setLoadingCards} />
                            )}
                        </button>
                        <button className="button-config size-not-effect"><div className='element-button-config'><Icon className='icon-config' icon="tabler:password" /><p>Cambiar Contraseña</p></div><Icon icon="material-symbols:arrow-forward-ios" /></button>
                        <button className="button-config size-not-effect"><div className='element-button-config'><Icon className='icon-config' icon="tabler:bell-filled" /><p>Notificaciones</p></div><Icon icon="material-symbols:arrow-forward-ios" /></button>
                        <button className="button-config size-not-effect" onClick={() => navigate("/pets")}><div className='element-button-config'><Icon className='icon-config' icon="tabler:paw" /><p>Mis Mascotas</p></div><Icon icon="material-symbols:arrow-forward-ios" /></button>
                        <button className="button-config size-not-effect" onClick={() => navigate("/my-services")}><div className='element-button-config'><Icon className='icon-config' icon="tabler:e-passport" /><p>Mis Servicios</p></div><Icon icon="material-symbols:arrow-forward-ios" /></button>
                        <button className="button-config size-not-effect" onClick={() => logOut()}><div className='element-button-config'><Icon className='icon-config' icon="tabler:logout" /><p>Cerrar Sesión</p></div><Icon icon="material-symbols:arrow-forward-ios" /></button>
                    </div>
                </div>
                <div className='config-buttons-container'>
                    <div className='config-general'>
                        <div className='title-config'><h2>Información</h2></div>
                        <a href="mailto:damianpracticas@gmail.com" className="button-config size-not-effect"><div className='element-button-config'><Icon className='icon-config' icon="tabler:mail" /><p>Contacto</p></div><Icon icon="material-symbols:arrow-forward-ios" /></a>
                        <button className="button-config size-not-effect" onClick={() => navigate("/payment-history")}><div className='element-button-config'><Icon className='icon-config' icon="tabler:cash" /><p>Historial de pagos</p></div><Icon icon="material-symbols:arrow-forward-ios" /></button>
                        <button className="button-config size-not-effect"><div className='element-button-config'><Icon className='icon-config' icon="tabler:device-mobile" /><p>Sobre la App</p></div><Icon icon="material-symbols:arrow-forward-ios" /></button>
                        <button className="button-config size-not-effect"><div className='element-button-config'><Icon className='icon-config' icon="tabler:file-description" /><p>Términos y condiciones</p></div><Icon icon="material-symbols:arrow-forward-ios" /></button>
                        <button className="button-config size-not-effect"><div className='element-button-config'><Icon className='icon-config' icon="tabler:shield-lock" /><p>Política de privacidad</p></div><Icon icon="material-symbols:arrow-forward-ios" /></button>
                        {/* <button className="button-config size-not-effect"><div className='element-button-config'><Icon className='icon-config' icon="tabler:share" /><p>Comparte esta app</p></div><Icon icon="material-symbols:arrow-forward-ios" /></button> */}
                    </div>
                </div>
                {openModal && (<Avatars setOpenModal={setOpenModal} userData={userData} setUserData={setUserData} setUpdatingAvatar={setUpdatingAvatar} />)}
                {showModalTopService && <TopServiceModal user={userData} checkTopService={checkTopService} closeTopModal={closeTopModal} />}
            </div>
        </div>
    );
};