import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import Loader from '../components/Loader';
import "./css/GeneralStyles.css";
import "./css/Login.css";

export default function VerifyEmail() {
    const token = localStorage.getItem("cookie_token");
    const { tokenVerify } = useParams();
    const navigate = useNavigate();
    const [verifing, setVerifing] = useState(false);
    const [verified, setVerified] = useState(null);
    const [send, setSend] = useState(false);

    /**
     * Verifica el token de verificación de email al montarse el componente.
     */
    useEffect(() => {
        if (tokenVerify != null) {
            setVerifing(true);
            verifyEmail();
        }
    }, []);


    /**
     * Maneja el click del botón y envía una solicitud para reenviar el correo de verificación.
     */
    const handleOnClick = () => {
        setVerifing(false);
        setSend(true);

        fetch(`${process.env.REACT_APP_API_URL}/api/send-email`, {
            method: 'GET',
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
            });
    };

    /**
     * Verifica el token de verificación de email de forma asíncrona.
     */
    const verifyEmail = async () => {
        let formData = new FormData();
        formData.append("token", tokenVerify);

        fetch(`${process.env.REACT_APP_API_URL}/api/verify-email`, {
            method: 'POST',
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                setVerified(!data.error);
            });
    };


    return (
        <div className='container alice-content'>
            <div className='center-container'>
                <div className='box'>
                    {!verifing ? (
                        <>
                            <p className='log-in-text'>Enviar codigo para verificar tu email</p>
                            <div className='forgot-buttons-container'>
                                {!send ? (
                                    <button type="submit" onClick={() => handleOnClick()} className="forgot-button login-button">Enviar</button>
                                ) : (
                                    <p>Consulta tu email</p>
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            {verified == null ? (
                                <Loader />
                            ) : verified ? (
                                <>
                                    <p className='log-in-text'>Tu email ha sido verificado, puedes cerrar esta pestaña.</p>
                                    <button type="submit" onClick={() => navigate("/dashboard")} className="forgot-button login-button">Volver al Dashboard</button>
                                </>
                            ) : (
                                <>
                                    <p className='log-in-text'>Tu email no se ha verificado, volver a enviar el codigo:</p>
                                    <button type="submit" onClick={() => handleOnClick()} className="forgot-button login-button">Enviar</button>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
