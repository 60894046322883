import React, { createContext, useContext, useState, useEffect } from "react";
import io from "socket.io-client";
import { useNavigate, useLocation } from 'react-router-dom';

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const id_user = localStorage?.getItem("id_user");
    const token = localStorage?.getItem("cookie_token");
    const [socket, setSocket] = useState(null);
    const [notNoLeidas, setNotNoLeidas] = useState(0);
    const [notificaciones, setNotificaciones] = useState([]);

    useEffect(() => {
        try {
            if (token) {
                if (location.pathname == "/login" || location.pathname == "/") {
                    navigate("/dashboard");
                }

                const newSocket = io(process.env.REACT_APP_SOCKET_URL, {
                    query: {
                        token: token,
                        id_user: id_user
                    }
                });

                setSocket(newSocket);

                return () => {
                    newSocket.disconnect();
                };
            }
        } catch (e) {

        }
    }, [token]);

    useEffect(() => {
        if (socket) {
            socket.on("getNotifications", (data) => {
                console.log(data.personal.concat(data.global));
                setNotificaciones(data.personal.concat(data.global));
                setNotNoLeidas(data.personal.concat(data.global).length);
            });
        }
    }, [socket]);

    const getNotificaciones = () => {
        socket.emit("getNotifications", {});
    }

    const deleteNotificacion = (id, type) => {
        socket.emit("deleteNotification", { id: id, type: type });
    }

    const sendNotificacion = (id) => {
        socket.emit("sendNotification", { id: id });
    }

    return (
        <SocketContext.Provider value={{
            socket,
            notificaciones,
            notNoLeidas,
            getNotificaciones,
            deleteNotificacion,
            sendNotificacion
        }}>
            {children}
        </SocketContext.Provider>
    );
};
