import React, { useEffect, useState } from 'react';
import './css/ContractInfo.css';
import './css/GeneralStyles.css';
import { Icon } from '@iconify/react';
import { useNavigate, useLocation } from 'react-router-dom';
import Loader from '../components/Loader';

export default function ContractInfo() {
    const token = localStorage.getItem('cookie_token');
    const location = useLocation();
    const navigate = useNavigate();
    const [loadContent, setLoadContent] = useState(false);
    const [infoService, setInfoService] = useState(location.state?.service ? location.state.service : false);

    /**
     * Obtener la información del servicio si está presente en el estado del navigate;
     * si no, redirigir a la página de búsqueda de servicios.
     */
    useEffect(() => {
        if (infoService != false) {
            if (infoService.user == undefined) {
                fetch(process.env.REACT_APP_API_URL + "/api/get-info-service/" + infoService.id, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + token,
                    },
                })
                    .then((response) => response.json())
                    .then((data) => {
                        setInfoService(data.service);
                        setLoadContent(true);
                    });
            } else {
                setLoadContent(true);
            }
        } else {
            navigate("/dashboard");
        }
    }, []);

    /**
     * Navega hacia atrás a la página de tarjetas o servicios, dependiendo del estado de la ubicación.
     */
    const goBack = () => {
        if (location.state?.cards) {
            navigate("/cards");
        } else {
            navigate("/services");
        }
    }

    return (
        <div className="container">
            <div className="content-wo-footer alice-content">
                {infoService && loadContent ? (
                    <div className='fade-in-animation'>
                        <div className="header-bar white-bar">
                            <button className="button-bar back-button-bar" size="icon" variant="ghost" onClick={() => goBack()}>
                                <Icon className='icon-bar back-icon-bar' icon="material-symbols:arrow-back-ios" />
                            </button>
                            <h1 className="title-bar white-bar-title">{infoService.user.name}</h1>
                            <button className="disabled-button" disabled></button>
                        </div>
                        <div className='white-container'>
                            <div className="profile-element">
                                <div className="image-contract-element">
                                    <img
                                        alt="Avatar"
                                        className="image-contract"
                                        src="https://source.unsplash.com/random"
                                    />
                                </div>
                            </div>
                            <div className="profile-contract-element">
                                <div className="name-contract-container">
                                    <h1>{infoService.user.name}</h1>
                                    <div className='ubication-contract'>
                                        <div className="icon-contract-container"><Icon icon="tabler:map-pin-filled" className="icon-contract-ubi" /></div><p>{infoService.municipality}</p>
                                    </div>
                                    <button className="like-button"><Icon icon="tabler:heart-filled" className="icon-like" /></button>
                                </div>
                            </div>
                            <div className='categories-contract'>
                                <div className='categories-container'>
                                    <div className='category category-contract'>
                                        <p className='title-category'>Tipo</p>
                                        <p className='text-category'>{infoService.category}</p>
                                    </div>
                                    <div className='category category-contract'>
                                        <p className='title-category'>Experiencia</p>
                                        <p className='text-category'>3 años</p>
                                    </div>
                                    <div className='category category-contract'>
                                        <p className='title-category'>A domicilio</p>
                                        <p className='text-category'>{infoService.home_service == 1 ? "Si" : "No"}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='disponibility-container'>
                                <Icon icon="tabler:calendar-month" className='disponibility-icon' />
                                <p>{infoService.price} €</p>
                                <div className='disponibility-button-container'>
                                    <a href={`tel:${infoService.user.phone_number}`} className='disponibility-button'><Icon icon="tabler:phone-call" className='disponibility-button-icon' /></a>
                                    <button className='disponibility-button'><Icon icon="tabler:brand-line" className='disponibility-button-icon' /></button>
                                </div>
                            </div>
                            <div className='description'>
                                <h3>Disponibilidad</h3>
                                {infoService.schedule != "" &&
                                    Object.entries(JSON.parse(infoService.schedule)).map(([day, hours]) => (
                                        <div key={day}><strong>{day}</strong>: {hours.start} - {hours.end}</div>
                                    ))
                                }
                            </div>
                        </div>
                        {infoService.reserved == 0 ? (
                            <div className='contract-button-container'>
                                <button className='contracted contracted-button' onClick={() => navigate("/contract", { state: { service: infoService } })}>Contratar Ahora</button>
                            </div>
                        ) : (
                            <div className='contract-button-container'>
                                <button className='contracted size-not-effect' disabled onClick={() => navigate("/contract", { state: { service: infoService } })}>Contratado</button>
                            </div>
                        )}
                    </div>
                ) : (
                    <Loader />
                )}
            </div>
        </div>
    );
};