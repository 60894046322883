import React, { useState, useEffect, useRef } from 'react';

export default function FrontCard({ setStartX, offsetX, setOffsetX, goNextProfile, cardRef, startX }) {
    /**
     * Maneja el evento de inicio de toque en un elemento específico.
     *
     * Params: 
     *   - e: El evento táctil.
     *   - index: El índice del elemento en el que se inició el toque.
     * Return: Ninguno.
     */
    const handleTouchStart = (e, index) => {
        setStartX(e.touches[0].clientX);
    };

    /**
     * Maneja el evento de desplazamiento de toque en la pantalla.
     *
     * Params: 
     *   - e: El evento táctil.
     * Return: Ninguno.
     */
    const handleTouchMove = (e) => {
        if (!startX) return;

        const x = e.touches[0].clientX;
        const deltaX = x - startX;

        setOffsetX(deltaX);
    };

    /**
     * Maneja el evento de finalización de toque en la pantalla.
     */
    const handleTouchEnd = () => {
        if (offsetX > 150) {
            goNextProfile(true);
        } else if (offsetX < -150) {
            goNextProfile(false);
        } else {
            cardRef.current.style.background = 'white';
        }

        setStartX(null);
        setOffsetX(0);
    };

    /**
     * Genera un color progresivo basado en la posición horizontal del desplazamiento.
     *
     * Params: Ninguno.
     * Return: (string) - Color en formato RGBA.
     */
    const generateProgressiveColor = () => {
        if (offsetX > 0) {
            return `rgba(72, 187, 120, ${Math.min(offsetX / 800, 0.5)})`;
        } else if (offsetX < 0) {
            return `rgba(229, 62, 62, ${Math.min(-offsetX / 800, 0.5)})`;
        } else {
            return `rgba(255, 255, 255, 0)`;
        }
    };

    return (
        <div
            className='card z-20'
            ref={cardRef}
            onTouchStart={(e) => handleTouchStart(e)}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            style={{
                transform: `translateX(${offsetX}px)`,
                background: generateProgressiveColor()
            }}
        >
            <div className="card-actions">
                <button className="card-actions-button card-actions-button-delete" onClick={() => goNextProfile(false)}>
                    Next
                </button>
                <button className="card-actions-button card-actions-button-connect" onClick={() => goNextProfile(true)}>
                    Connect
                </button>
            </div>
        </div>
    );
};