import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useSocket } from "../services/socket";
import Loader from '../components/Loader';

export default function AuthCookie() {
    const { getNotificaciones } = useSocket();
    const navigate = useNavigate();
    const token = localStorage.getItem("cookie_token");
    const [showModal, setShowModal] = useState(false);

    /**
     * Efecto que verifica si el usuario está autenticado al cargar el componente.
     */
    useEffect(() => {
        isLogged();
    }, []);


    /**
     * Verifica si el usuario está autenticado al hacer una solicitud al perfil del usuario.
    */
    function isLogged() {
        try {
            fetch(process.env.REACT_APP_API_URL + "/api/user-profile", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        navigate("/login");
                    }
                    getNotificaciones();
                    return response.json();
                })
                .then((data) => {
                    if (data.message == "Unauthenticated.") {
                        navigate("/login");
                    } else {
                        localStorage.setItem("id_user", data.userData.id);
                        localStorage.setItem("email_verified_at", data.userData.email_verified_at);
                        setShowModal(!data.userData.email_verified_at)
                    }
                })
                .catch((error) => {
                    navigate("/login");
                });
        } catch (error) {
            navigate("/login");
        }
    }

    return (
        <>
            {showModal && (
                <div className="modal-bg">
                    <div className="modal rating-modal">
                        <div className="fade-in-animation">
                            <p>Porfavor verifica tu email, haz click en el siguiente boton para verificar:</p>
                            <button className="close-button" onClick={() => setShowModal(false)}>
                                <Icon icon="tabler:x" />
                            </button>
                            <div className="verify-button">
                                <button type="submit" onClick={() => navigate("/verify-email")} className="forgot-button login-button">Verificar</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
};

