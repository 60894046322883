import React, { useState, useRef } from 'react';
import "./css/Slider.css";

export default function Slider({ images, click, clickFunction }) {
    const containerRef = useRef(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [startX, setStartX] = useState(0);

    /**
     * Guarda la posición X inicial del evento táctil.
     *
     * Params: event (object) - El evento táctil.
     * Return: Ninguno.
     */
    const handleTouchStart = (event) => {
        setStartX(event.touches[0].clientX);
    };

    /**
     * Maneja el evento de finalización del toque.
     *
     * Params: event (object) - El evento táctil.
     * Return: Ninguno.
     */
    const handleTouchEnd = (event) => {
        const endX = event.changedTouches[0].clientX;
        const deltaX = endX - startX;
        if (deltaX > 50) {
            // Deslizamiento hacia la derecha, retroceder imagen
            prevImage();
        } else if (deltaX < -50) {
            // Deslizamiento hacia la izquierda, avanzar imagen
            nextImage();
        }
    };

    /**
     * Avanza a la siguiente imagen en el array de imágenes.
     */
    const nextImage = () => {
        const nextIndex = (currentImageIndex + 1) % images.length;
        setCurrentImageIndex(nextIndex);
    };

    /**
     * Retrocede a la anterior imagen en el array de imágenes.
     */
    const prevImage = () => {
        const prevIndex = (currentImageIndex - 1 + images.length) % images.length;
        setCurrentImageIndex(prevIndex);
    };

    return (
        <>
            <div
                className="slider-container"
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                ref={containerRef}
            >
                <img src={images[currentImageIndex]} alt="Slide" className="image" />

                <div className="buttons-container">
                    <button onClick={prevImage} className="prev-button">&#10094;</button>
                    <button onClick={nextImage} className="next-button">&#10095;</button>
                </div>
            </div>
            <div className="container-circles-slider">
                {images.map((image, index) => {
                    return (
                        <div className={'circle-slider ' + (index == currentImageIndex && "selected-circle")}></div>
                    )
                })}
            </div>
            {click == undefined ? null : (
                <div className='select-avatar'>
                    <button onClick={() => clickFunction(images[currentImageIndex])}>Seleccionar Avatar</button>
                </div>
            )}
        </>
    )
}