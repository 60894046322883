import React, { useState, useRef, useEffect } from 'react';
import { Calendar, Whisper, Popover, Badge } from 'rsuite';
import { Icon } from '@iconify/react';
import Footer from '../components/FooterMenu';
import Loader from '../components/Loader';
import './css/Calendar.css';
import './css/GeneralStyles.css';
import 'rsuite/dist/rsuite.min.css';

export default function CalendarPage() {
    const token = localStorage.getItem('cookie_token');
    const [showModal, setShowModal] = useState(false);
    const [listModal, setListModal] = useState([]);
    const [dataModal, setOnlyDataModal] = useState([]);
    const [toDo, setToDo] = useState(null);

    /**
     * Realiza una solicitud al servidor para buscar el horario, actualiza el estado del horario y las reservas, y los combina en un solo array.
     */
    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + "/api/search-schedule", {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                data.services.forEach(obj => {
                    obj.reserved = false;
                });

                data.reserved_services.forEach(obj => {
                    obj.reserved = true;
                });

                const combinedArray = [...data.services, ...data.reserved_services];
                console.log(combinedArray);
                setToDo(combinedArray);
            });
    }, [])

    /**
     * Obtiene la lista de tareas pendientes para un día específico de la semana.
     * 
     * Params: date (Date) - La fecha para la que se desea obtener la lista de tareas pendientes.
     * Return: Array - La lista de tareas pendientes para el día especificado.
     */
    function getTodoList(date) {
        const day = date.getDay();
        let dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

        switch (dayNames[day]) {
            case "Monday":
                return getDaySchedule(dayNames[day]);
            case "Tuesday":
                return getDaySchedule(dayNames[day]);
            case "Wednesday":
                return getDaySchedule(dayNames[day]);
            case "Thursday":
                return getDaySchedule(dayNames[day]);
            case "Friday":
                return getDaySchedule(dayNames[day]);
            case "Saturday":
                return getDaySchedule(dayNames[day]);
            case "Sunday":
                return getDaySchedule(dayNames[day]);
            default:
                return [];
        }

    }

    /**
     * Obtiene el horario del día especificado de la lista de tareas pendientes.
     * 
     * Params: nameDay (string) - El nombre del día de la semana para el que se desea obtener el horario.
     * Return: Array - El horario del día especificado.
     */
    function getDaySchedule(nameDay) {
        let daySchedule = toDo;
        const result = [];

        const categoryTranslation = {
            "Nutritionist": "Nutricionista",
            "Educator": "Educador",
            "Caregiver": "Cuidador"
        };

        const animalTranslation = {
            "Cat": "Gato",
            "Dog": "Perro",
            "Turtle": "Tortuga",
            "Rabbit": "Conejo"
        };


        daySchedule.forEach(day => {
            let schedule = JSON.parse(day.schedule);
            let keys = Object.keys(schedule);

            keys.forEach((key) => {
                if (key == nameDay) {
                    if (schedule[key].length == undefined) {
                        schedule[key] = [schedule[key]];
                    }

                    for (let i = 0; i < schedule[key].length; i++) {
                        console.log(i);
                        const { start, end } = schedule[key][i];
                        const translatedCategory = categoryTranslation[day.category] || day.category;
                        const translatedAnimalType = animalTranslation[day.animal_type] || day.animal_type;
                        let title = "";
                        if (day.reserved) {
                            title = `${day.user.name} hace de ${translatedCategory} para tu ${translatedAnimalType}`;
                        } else {
                            title = `Eres ${translatedCategory} para ${translatedAnimalType}`;
                        }
                        result.push({ timeStart: start, timeEnd: end, title: title, reserved: day.reserved });
                    }
                }
            });
        });

        console.log(result);
        return result;
    }

    /**
     * Renderiza la celda del calendario con la lista de tareas pendientes para la fecha especificada.
     * 
     * Params: date (Date) - La fecha para la cual se renderiza la celda del calendario.
     * Return: ReactElement | null - La lista de tareas pendientes para la fecha especificada.
     */
    function renderCell(date) {
        const list = getTodoList(date);
        const displayList = list.filter((item, index) => index < 2);

        if (list.length > 0) {
            const moreCount = list.length - displayList.length;

            return (
                <ul className="calendar-todo-list">
                    {displayList.map((item, index) => (
                        <li key={index} onClick={() => setDataModal(list, date)} cla>
                            {item.reserved ? (
                                <div className='container-bolita'><div className='bolita rojo'></div><b>{item.timeStart} - {item.timeEnd}</b> - {item.title}</div>
                            ) : (
                                <div className='container-bolita'><div className='bolita azul'></div><b>{item.timeStart} - {item.timeEnd}</b> - {item.title}</div>
                            )}
                        </li>
                    ))}
                    {moreCount > 0 && (
                        <>
                            <a onClick={() => setDataModal(list, date)}>{moreCount} más</a>
                        </>
                    )}
                </ul>
            );
        }

        return null;
    }

    /**
     * Establece los datos para el modal de visualización de tareas.
     * 
     * Params: list (Array) - La lista de tareas.
     *         date (Date) - La fecha para la cual se establecen los datos del modal.
     * Return: Ninguno.
     */
    const setDataModal = (list, date) => {
        const currentDate = new Date(date);
        const dayOfWeek = new Intl.DateTimeFormat('es-ES', { weekday: 'long' }).format(currentDate);
        const month = new Intl.DateTimeFormat('es-ES', { month: 'long' }).format(currentDate);
        const dayOfMonth = currentDate.getDate();
        const year = currentDate.getFullYear();

        setOnlyDataModal({
            day: capitalizeFirstLetter(dayOfWeek),
            month: capitalizeFirstLetter(month),
            dayOfMonth: dayOfMonth,
            year: year
        });

        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }

        setListModal(list);
        setShowModal(!showModal);
    }

    return (
        <div className='general-container-calendar'>
            {toDo != null ? (
                <div className="calendar-container">
                    <Calendar bordered renderCell={renderCell} />
                    {showModal && (
                        <div className="modal-bg ">
                            <div className="modal modal-date">
                                <div className="modal-header">
                                    <div className="modal-date-text">
                                        {dataModal.day}, {dataModal.dayOfMonth} de {dataModal.month} del {dataModal.year}
                                    </div>
                                    <button className="close-button" onClick={() => setShowModal(false)}>
                                        <Icon icon="tabler:x" />
                                    </button>
                                </div>
                                <div className='line-date'></div>
                                <div className='modal-date-scroll-hidden'>
                                    <div className='modal-date-scroll-body'>
                                        <div className='modal-date-body'>
                                            {listModal.map((item, index) => (
                                                <div className="modal-date-element">
                                                    <div key={index} className='modal-date-element-text'>
                                                        {item.reserved ? (
                                                            <div className="container-bolita"><div className='bolita naranja'></div></div>
                                                        ) : (
                                                            <div className="container-bolita"><div className='bolita azul'></div></div>
                                                        )}
                                                        <div className='modal-date-time'>
                                                            <b>{item.timeStart} h</b>
                                                            <b>{item.timeEnd} h</b>
                                                        </div>
                                                        <div className='modal-date-element-title'>
                                                            {item.title}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <Loader />
            )}
            <Footer />
        </div>
    );

};