import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../pages/css/Profile.css';
import '../pages/css/GeneralStyles.css';

export default function EditPet() {
    const location = useLocation();
    const pet = location.state ? location.state.pet : null;
    const navigate = useNavigate();
    const token = localStorage.getItem("cookie_token");

    const [name, setName] = useState(pet.name);
    const [gender, setGender] = useState(pet.gender);
    const [type, setType] = useState(pet.type);
    const [age, setAge] = useState(pet.age);
    const [weight, setWeight] = useState(pet.weight);
    const [breed, setBreed] = useState(pet.breed);
    const [breedSpecification, setBreedSpecification] = useState(pet.breed_specification);
    const [reactive, setReactive] = useState(pet.reactive);
    const [reactiveDescription, setReactiveDescription] = useState(pet.reactive_description);
    const [microchipped, setMicrochipped] = useState(pet.microchipped);
    const [sterilizationStatus, setSterilizationStatus] = useState(pet.sterilization_status);
    const [needsOutside, setNeedsOutside] = useState(pet.needs_outside);
    const [getsAlongWithChildren, setGetsAlongWithChildren] = useState(pet.gets_along_with_children);
    const [getsAlongWithDogs, setGetsAlongWithDogs] = useState(pet.gets_along_with_dogs);
    const [getsAlongWithCats, setGetsAlongWithCats] = useState(pet.gets_along_with_cats);
    const [adoptionDate, setAdoptionDate] = useState(pet.adoption_date);
    const [observations, setObservations] = useState(pet.observations);
    const [careInformation, setCareInformation] = useState(pet.care_information);
    const [bathroomFrequency, setBathroomFrequency] = useState(pet.bathroom_frequency);
    const [energyLevel, setEnergyLevel] = useState(pet.energy_level);
    const [feedingRoutine, setFeedingRoutine] = useState(pet.feeding_routine);
    const [canStayAlone, setCanStayAlone] = useState(pet.can_stay_alone);
    const [medicationInfo, setMedicationInfo] = useState(pet.medication_info);
    const [veterinaryInfo, setVeterinaryInfo] = useState(pet.veterinary_info);
    const [petInsuranceProvider, setPetInsuranceProvider] = useState(pet.pet_insurance_provider);

    /**
     * Redirecciona a la página de perfil si no se encuentra una mascota.
     */
    useEffect(() => {
        if (pet == null) {
            navigate("profile");
        }
    }, []);

    /**
     * Envía los datos editados de la mascota al servidor.
     * 
     * Params: e (evento) - El evento de formulario.
     * Return: Ninguno.
     */
    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('id', pet.id);
        formData.append('name', name);
        formData.append('gender', gender);
        formData.append('type', type);
        formData.append('age', age);
        formData.append('weight', weight);
        formData.append('breed', breed);
        formData.append('breedSpecification', breedSpecification);
        formData.append('reactive', reactive ? '1' : '0');
        formData.append('reactiveDescription', reactiveDescription);
        formData.append('microchipped', microchipped ? '1' : '0');
        formData.append('sterilizationStatus', sterilizationStatus);
        formData.append('needsOutside', needsOutside ? '1' : '0');
        formData.append('getsAlongWithChildren', getsAlongWithChildren ? '1' : '0');
        formData.append('getsAlongWithDogs', getsAlongWithDogs ? '1' : '0');
        formData.append('getsAlongWithCats', getsAlongWithCats ? '1' : '0');
        formData.append('adoptionDate', adoptionDate);
        formData.append('observations', observations);
        formData.append('careInformation', careInformation);
        formData.append('bathroomFrequency', bathroomFrequency);
        formData.append('energyLevel', energyLevel);
        formData.append('feedingRoutine', feedingRoutine);
        formData.append('canStayAlone', canStayAlone);
        formData.append('medicationInfo', medicationInfo);
        formData.append('veterinaryInfo', veterinaryInfo);
        formData.append('petInsuranceProvider', petInsuranceProvider);

        fetch(process.env.REACT_APP_API_URL + "/api/edit-pet", {
            method: "POST",
            body: formData,
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.error == false) {
                    navigate("/profile");
                }
            });
    };

    return (
        <div className="container">
            <div className="session">
                <div className='content'>
                    <form onSubmit={handleSubmit} className="form-container-addPet">
                        <h4 className="log-in-title">Editar Mascota</h4>
                        <div className="form-column">
                            <div className="input-form">
                                <label>Nombre: *</label>
                                <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className="input"
                                />
                            </div>
                            <div className="input-form">
                                <label>Género: *</label>
                                <select
                                    value={gender}
                                    onChange={(e) => setGender(e.target.value)}
                                    className="input"
                                >
                                    <option value="" disabled>Selecciona un género</option>
                                    <option value="Male">Macho</option>
                                    <option value="Female">Hembra</option>
                                </select>
                            </div>
                            <div className="input-form">
                                <label>Tipo: *</label>
                                <select
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                    className="input"
                                >
                                    <option value="" disabled>Selecciona un tipo</option>
                                    <option value="Dog">Perro</option>
                                    <option value="Cat">Gato</option>
                                    <option value="Turtle">Tortuga</option>
                                    <option value="Rabbit">Conejo</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-column">
                            <div className="input-form">
                                <label>Edad: *</label>
                                <input
                                    type="number"
                                    value={age}
                                    onChange={(e) => setAge(e.target.value)}
                                    className="input"
                                />
                            </div>
                            <div className="input-form">
                                <label>Peso: *</label>
                                <input
                                    type="number"
                                    value={weight}
                                    onChange={(e) => setWeight(e.target.value)}
                                    className="input"
                                />
                            </div>
                            <div className="input-form">
                                <label>Raza: *</label>
                                <select
                                    value={breed}
                                    onChange={(e) => setBreed(e.target.value)}
                                    className="input"
                                >
                                    <option value="" disabled>Selecciona una raza</option>
                                    <option value="Breed">Raza</option>
                                    <option value="Mixed">Mestizo</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-column">
                            <div className="input-form">
                                <label>Especificación de Raza: *</label>
                                <input
                                    type="text"
                                    value={breedSpecification}
                                    onChange={(e) => setBreedSpecification(e.target.value)}
                                    className="input"
                                />
                            </div>
                            <div className="input-form">
                                <label>Reactiva: *</label>
                                <input
                                    type="checkbox"
                                    checked={reactive}
                                    onChange={(e) => setReactive(e.target.checked)}
                                    className="input"
                                />
                            </div>
                            <div className="input-form">
                                <label>Descripción Reactiva:</label>
                                <textarea
                                    value={reactiveDescription}
                                    onChange={(e) => setReactiveDescription(e.target.value)}
                                    className="input"
                                />
                            </div>
                        </div>
                        <div className="form-column">
                            <div className="input-form">
                                <label>Microchip:</label>
                                <input
                                    type="checkbox"
                                    checked={microchipped}
                                    onChange={(e) => setMicrochipped(e.target.checked)}
                                    className="input"
                                />
                            </div>
                            <div className="input-form">
                                <label>Estado de Esterilización:</label>
                                <select
                                    value={sterilizationStatus}
                                    onChange={(e) => setSterilizationStatus(e.target.value)}
                                    className="input"
                                >
                                    <option value="" disabled>Selecciona un estado de esterilización</option>
                                    <option value="Castrated">Castrado</option>
                                    <option value="Sterilized">Esterilizado</option>
                                    <option value="None">Nada</option>
                                </select>
                            </div>
                            <div className="input-form">
                                <label>Hace sus necesidades fuera de casa:</label>
                                <input
                                    type="checkbox"
                                    checked={needsOutside}
                                    onChange={(e) => setNeedsOutside(e.target.checked)}
                                    className="input"
                                />
                            </div>
                        </div>
                        <div className="form-column">
                            <div className="input-form">
                                <label>Se Lleva Bien con Niños:</label>
                                <input
                                    type="checkbox"
                                    checked={getsAlongWithChildren}
                                    onChange={(e) => setGetsAlongWithChildren(e.target.checked)}
                                    className="input"
                                />
                            </div>
                            <div className="input-form">
                                <label>Se Lleva Bien con Perros:</label>
                                <input
                                    type="checkbox"
                                    checked={getsAlongWithDogs}
                                    onChange={(e) => setGetsAlongWithDogs(e.target.checked)}
                                    className="input"
                                />
                            </div>
                            <div className="input-form">
                                <label>Se Lleva Bien con Gatos:</label>
                                <input
                                    type="checkbox"
                                    checked={getsAlongWithCats}
                                    onChange={(e) => setGetsAlongWithCats(e.target.checked)}
                                    className="input"
                                />
                            </div>
                        </div>
                        <div className="form-column">
                            <div className="input-form">
                                <label>Fecha de Adopción:</label>
                                <input
                                    type="date"
                                    value={adoptionDate}
                                    onChange={(e) => setAdoptionDate(e.target.value)}
                                    className="input"
                                />
                            </div>
                        </div>
                        <div className="form-column">
                            <div className="input-form">
                                <label>Frecuencia de Baño:</label>
                                <input
                                    type="text"
                                    value={bathroomFrequency}
                                    onChange={(e) => setBathroomFrequency(e.target.value)}
                                    className="input"
                                />
                            </div>
                            <div className="input-form">
                                <label>Nivel de Energía:</label>
                                <select
                                    value={energyLevel}
                                    onChange={(e) => setEnergyLevel(e.target.value)}
                                    className="input"
                                >
                                    <option value="" disabled>Select energy level</option>
                                    <option value="None">Nada</option>
                                    <option value="Low">Baja</option>
                                    <option value="Medium">Media</option>
                                    <option value="High">Alta</option>

                                </select>
                            </div>
                            <div className="input-form">
                                <label>Puede Estar Solo:</label>
                                <select
                                    value={canStayAlone}
                                    onChange={(e) => setCanStayAlone(e.target.value)}
                                    className="input"
                                >
                                    <option value="" disabled>Select duration</option>
                                    <option value="None">Nada</option>
                                    <option value="1 hour">1 Hora</option>
                                    <option value="2 hours">2 Horas</option>
                                    <option value="3 hours">3 Horas</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-column">
                            <div className="input-form">
                                <label>Rutina de Alimentación:</label>
                                <input
                                    type="text"
                                    value={feedingRoutine}
                                    onChange={(e) => setFeedingRoutine(e.target.value)}
                                    className="input"
                                />
                            </div>
                            <div className="input-form">
                                <label>Información de Medicación:</label>
                                <input
                                    type="text"
                                    value={medicationInfo}
                                    onChange={(e) => setMedicationInfo(e.target.value)}
                                    className="input"
                                />
                            </div>
                            <div className="input-form">
                                <label>Información Veterinaria:</label>
                                <input
                                    type="text"
                                    value={veterinaryInfo}
                                    onChange={(e) => setVeterinaryInfo(e.target.value)}
                                    className="input"
                                />
                            </div>
                        </div>
                        <div className="form-column">
                            <div className="input-form">
                                <label>Información de Cuidado:</label>
                                <textarea
                                    value={careInformation}
                                    onChange={(e) => setCareInformation(e.target.value)}
                                    className="input"
                                />
                            </div>
                            <div className="input-form">
                                <label>Proveedor de Seguro para Mascotas:</label>
                                <input
                                    type="text"
                                    value={petInsuranceProvider}
                                    onChange={(e) => setPetInsuranceProvider(e.target.value)}
                                    className="input"
                                />
                            </div>
                            <div className="input-form">
                                <label>Observaciones:</label>
                                <textarea
                                    value={observations}
                                    onChange={(e) => setObservations(e.target.value)}
                                    className="input"
                                />
                            </div>
                        </div>
                        <div className='login-buttons-container add-mascota'>
                            <button type="submit" className=' '>Editar Mascota</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
