import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { useNavigate, useLocation } from 'react-router-dom';

export default function WeekCalendar() {
    const navigate = useNavigate();
    const nameDays = ["LUN", "MAR", "MIE", "JUE", "VIE", "SAB", "DOM"];
    const months = ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO", "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"];

    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay() - 1;
    const currentDayOfMonth = currentDate.getDate();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const [daysOfWeek, setDaysOfWeek] = useState(false);
    const [calendarWeek, setCalendarWeek] = useState();

    /**
     * Establece el estado inicial del calendario con la semana actual y los días de la semana.
     */
    useEffect(() => {
        setCalendarWeek({
            firstDay: getDay((currentDayOfMonth - currentDayOfWeek), currentMonth, currentYear),
            month: currentMonth,
            year: currentYear,
        })

        setDaysOfWeek(getDaysOfWeek((currentDayOfMonth - currentDayOfWeek), currentMonth, currentYear));
    }, []);

    /**
     * Obtiene los días de la semana para el calendario.
     * 
     * Params: day (number) - El día de la semana actual, month (number) - El mes actual, year (number) - El año actual.
     * Return: Array - Los días de la semana con su diseño correspondiente.
     */
    const getDaysOfWeek = (day, month, year) => {
        let daysOfActualWeek = [];
        for (let i = 0; i < 7; i++) {
            let forDay = {
                day: getDay(day + i, month, year),
                design: ""
            }

            if (currentDayOfMonth == day + i && currentMonth == month && currentYear == year) {
                forDay.design = " current-day";
            } else if (currentYear > year) {
                forDay.design = " disabled-day";
            } else {
                if (currentMonth > month && currentYear == year) {
                    forDay.design = " disabled-day";
                } else {
                    if (currentMonth == month && currentYear == year && currentDayOfMonth > day + i) {
                        forDay.design = " disabled-day";
                    }
                }
            }

            daysOfActualWeek.push(forDay);
        }

        return daysOfActualWeek;
    }

    /**
     * Obtiene el día correcto del mes, considerando los días de meses anteriores y posteriores si es necesario.
     * 
     * Params: day (number) - El día del mes, month (number) - El mes (0-11), year (number) - El año.
     * Return: number - El día corregido del mes.
     */
    const getDay = (day, month, year) => {
        let correctDay = day;
        let backMonthDays = new Date(year, month + 1, 0).getDate();

        if (day < 1) {
            if (month == 0) {
                month = 11;
            } else {
                month--;
            }

            backMonthDays = new Date(year, month + 1, 0).getDate()
            correctDay = backMonthDays + correctDay;
        } else if (day > backMonthDays) {
            correctDay = day - backMonthDays;
        }

        return correctDay;
    }

    /**
     * Cambia el mes mostrado en el calendario hacia adelante o hacia atrás.
     * 
     * Params: mode (number) - El modo de cambio de mes: 1 para adelante, -1 para atrás.
     * Return: Ninguno.
     */
    const changeMonth = (mode) => {
        let actualFirstDayOfWeek = calendarWeek.firstDay;
        let actualMonth = calendarWeek.month;
        let actualYear = calendarWeek.year;

        let monthMaxDay = new Date(actualYear, actualMonth + 1, 0).getDate();
        if (mode == 1) {
            let lastDayOfWeek = calendarWeek.firstDay + 13;

            if (monthMaxDay < lastDayOfWeek) {
                actualMonth += 1;
                if (actualMonth > 11) {
                    actualYear++;
                    actualMonth = 0;
                }
                actualFirstDayOfWeek = actualFirstDayOfWeek - monthMaxDay + 7;
            } else {
                actualFirstDayOfWeek += 7;
            }
        } else {
            let firstDayOfWeek = calendarWeek.firstDay - 7;
            monthMaxDay = new Date(actualYear, actualMonth, 0).getDate();

            if (1 > firstDayOfWeek) {
                actualMonth -= 1;
                if (actualMonth < 0) {
                    actualYear--;
                    actualMonth = 11;
                }
                actualFirstDayOfWeek = actualFirstDayOfWeek + monthMaxDay - 7;
            } else {
                actualFirstDayOfWeek -= 7;
            }
        }

        setCalendarWeek({
            firstDay: actualFirstDayOfWeek,
            month: actualMonth,
            year: actualYear,
        })

        setDaysOfWeek(getDaysOfWeek(actualFirstDayOfWeek, actualMonth, actualYear));
    }

    return (
        <>
            {calendarWeek && (
                <>
                    <div className="header-bar white-bar">
                        <button className="button-bar back-button-bar" size="icon" variant="ghost" onClick={() => navigate("/services")}>
                            <Icon className='icon-bar back-icon-bar' icon="material-symbols:arrow-back-ios" />
                        </button>
                        <h1 className="title-bar white-bar-title">{months[calendarWeek.month]} {calendarWeek.year}</h1>
                        <button className="disabled-button" disabled></button>
                    </div>
                    <div className='nav-bar days-week'>
                        {daysOfWeek && (
                            <div className='nav-bar-elements'>
                                <button className="week-button" size="icon" variant="ghost" onClick={() => changeMonth(0)}>
                                    <Icon className='icon-day' icon="material-symbols:arrow-back-ios" />
                                </button>
                                {daysOfWeek.map((day, index) => {
                                    return (
                                        <div className={'day ' + day.design} key={index}>
                                            <p>{nameDays[index]}</p>
                                            <h2>{day.day}</h2>
                                        </div>
                                    )
                                })}
                                <button className="week-button" size="icon" variant="ghost" onClick={() => changeMonth(1)}>
                                    <Icon className='icon-day' icon="material-symbols:arrow-forward-ios" />
                                </button>
                            </div>
                        )}
                    </div>
                </>
            )
            }
        </>
    );
};