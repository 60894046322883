import React, { useEffect } from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import './css/PayPalButton.css';

export default function PayPalButton({ price, contract }) {
    /**
     * Maneja el éxito de un pago.
     *
     * Params:
     *   details (object): Objeto con detalles del pago.
     *   data (object): Objeto con información adicional del pago proporcionada por la pasarela de pagos.
     * Return:
     *   Ninguno. (Solo registra el éxito del pago en la consola)
     */
    const handlePaymentSuccess = (details, data) => {
        contract();
        console.log('Pago exitoso');
    };

    /**
     * Maneja el error de un pago.
     *
     * Params:
     *   error (object): Objeto con información del error proporcionado por la pasarela de pagos.
     * Return:
     *   Ninguno. (Solo registra el error del pago en la consola)
     */
    const handlePaymentError = (error) => {
        console.error('Error de pago', error);
    };

    /**
     * Maneja la cancelación de un pago.
     *
     * Params:
     *   data (object): Objeto con información de la cancelación proporcionada por la pasarela de pagos.
     * Return:
     *   Ninguno. (Solo registra la cancelación del pago en la consola)
     */
    const handlePaymentCancel = (data) => {
        console.log('Pago cancelado', data);
    };

    /**
     * Convierte un número en formato de punto flotante a una cadena de texto con dos decimales.
     *
     * Params:
     *   num (number): El número entero o flotante que se va a convertir.
     * Return:
     *   string: La cadena de texto formateada con dos decimales.
     *            Si el número no es válido, devuelve un mensaje de error.
     */
    function convertToFormattedString(num) {
        if (typeof num == 'number' && !isNaN(num)) {
            console.log(num.toFixed(2));
            return num.toFixed(2);
        } else {
            return 'Invalid input. Please provide a valid integer or float.';
        }
    }

    return (
        <div className="paypal-button">
            <PayPalScriptProvider options={{ "client-id": "AYVs3TPMSGk7iHDC44xCUqWeeCA4iQzTIUzMi4V7X7gxcAXWjYjcZhAYTZaiUMINtFABhItPyGHZ5dNo" }}>
                <PayPalButtons
                    style={{ layout: "horizontal" }}
                    createOrder={(data, actions) => {
                        return actions.order.create({
                            purchase_units: [{
                                amount: {
                                    value: convertToFormattedString(price)
                                }
                            }]
                        });
                    }}
                    onApprove={(data, actions) => {
                        return actions.order.capture().then(function (details) {
                            handlePaymentSuccess(details, data);
                        });
                    }}
                    onError={handlePaymentError}
                    onCancel={handlePaymentCancel}
                />
            </PayPalScriptProvider>
        </div>
    );
};
