import React, { useState } from "react";
import { useNavigate, Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import Logo from '../images/logo.jpeg';
import "./css/GeneralStyles.css";
import "./css/Login.css";

export default function Register() {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [eye, setEye] = useState(true);
    const [eyeConfirmed, setEyeConfirmed] = useState(true);

    /**
     * Maneja el envío del formulario de registro de usuario.
     *
     * Params:
     *   e (Event): Evento del formulario enviado.
     * Return: Ninguno.
     */
    const registerUser = (e) => {
        e.preventDefault();

        var validRegexEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        var validRegexPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,16}$/;

        if (validRegexEmail.test(email) && validRegexPassword.test(password)) {
            if (password === confirmPassword) {
                var formDataUser = new FormData();
                formDataUser.append("name", name);
                formDataUser.append("email", email);
                formDataUser.append("password", password);

                fetch(process.env.REACT_APP_API_URL + "/api/register", {
                    method: "POST",
                    body: formDataUser
                })
                    .then(response => response.json())
                    .then(data => {
                        navigate('/login');
                    });
            } else {
                setErrorMessage("Las contraseñas no coinciden");
            }
        } else {
            if (!validRegexEmail.test(email) && !validRegexPassword.test(password)) {
                setErrorMessage("La contraseña debe contener mayúsculas, minúsculas, caracteres especiales y además la dirección de correo electrónico es incorrecta.");
            } else if (!validRegexEmail.test(email)) {
                setErrorMessage("La dirección de correo electrónico es incorrecta.");
            } else if (!validRegexPassword.test(password)) {
                setErrorMessage("La contraseña debe contener mayúsculas, minúsculas, y caracteres especiales.");
            }
        }
    }

    return (
        <div className="container">
            <div className="session session-login">
                <form onSubmit={registerUser} className="content">
                    <div className='log-in-logo-container'>
                        <img className='log-in-logo' src={Logo} alt="Logo" />
                    </div>
                    <h4 className="log-in-title">Crear una cuenta</h4>
                    <div className="input-container">
                        <input
                            type="text"
                            className="input"
                            placeholder="Enter your Name"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <input
                            type="text"
                            className="input"
                            placeholder="Enter your Email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {eye ? (
                            <div className='password-container'>
                                <input
                                    type="password"
                                    className="input"
                                    placeholder="Password"
                                    id='password'
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <Icon icon="tabler:eye-closed" onClick={() => setEye(!eye)} />
                            </div>
                        ) : (
                            <div className='password-container'>
                                <input
                                    type="text"
                                    className="input"
                                    placeholder="Password"
                                    id='password'
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <Icon icon="tabler:eye" onClick={() => setEye(!eye)} />
                            </div>
                        )}
                        {eyeConfirmed ? (
                            <div className='password-container'>
                                <input
                                    type="password"
                                    className="input"
                                    placeholder="Confirm your Password"
                                    id="confirm-password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                                <Icon icon="tabler:eye-closed" onClick={() => setEyeConfirmed(!eyeConfirmed)} />
                            </div>
                        ) : (
                            <div className='password-container'>
                                <input
                                    type="text"
                                    className="input"
                                    placeholder="Confirm your Password"
                                    id="confirm-password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                                <Icon icon="tabler:eye" onClick={() => setEyeConfirmed(!eyeConfirmed)} />
                            </div>
                        )}
                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                    </div>
                    <div className='log-in-button-container'>
                        <button type="submit" className="login-button main-button">Register</button>
                    </div>
                    <div className='log-in-link-buttons-container'>
                        <div className='login-links-container'>
                            <div className="link-container">
                                <p className='link-text'>Ya estas registrado?</p><Link to="/login" className='link'>Iniciar Sesión</Link>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
