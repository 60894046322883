import { useNavigate } from "react-router-dom";
import "./css/GeneralStyles.css";
import "./css/App.css";
import PortadasAnimal from '../images/portada-animales.png';

export default function App() {
  const navigate = useNavigate();

  return (
    <div className="container">
      <div className="background-portada content-wo-footer">
        <div>
          <div className='container-image'>
            <img src={PortadasAnimal}></img>
          </div>
          <div className='container-text'>
            <h1>La app para</h1>
            <h1>CUIDAR MASCOTAS</h1>
            <div className='first-paragraph'><h3>¿Cuidas mascotas o necesitas que te cuiden las tuyas?</h3></div>
            <div className='second-paragraph'><h4>Te ayudamos a gestionar todo lo que tu mascota pueda necesitar</h4></div>
          </div>
          <div className='container-buttons-portada'>
            <button className='button-portada' onClick={() => navigate("/login")}>Iniciar Sesión</button>
            <button className='button-portada' onClick={() => navigate("/register")}>Registro</button>
          </div>
        </div>
      </div>
    </div>
  );
}