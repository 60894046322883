import React, { useEffect, useState } from 'react';
import './css/ContractInfo.css';
import './css/GeneralStyles.css';
import { Icon } from '@iconify/react';
import { useNavigate, useLocation } from 'react-router-dom';

export default function PaymentHistory() {
    const token = localStorage.getItem("cookie_token");
    const location = useLocation();
    const navigate = useNavigate();
    const [payments, setPayments] = useState(null);
    const [filterPayments, setFilterPayments] = useState(null);
    const [focus, setFocus] = useState(0);

    /**
     * Realiza la carga inicial de los pagos al montarse el componente.
     */
    useEffect(() => {
        getPayments();
    }, []);

    /**
     * Obtiene la lista de pagos del usuario logueado.
     *
     * En caso de error, se registra el error en la consola pero no se actualiza el estado.
     */
    const getPayments = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/payments-user`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            });

            const jsonData = await response.json();
            if (!jsonData.error) setPayments(jsonData);
            setFilterPayments(jsonData.payer);
        } catch (error) {
            console.log('Error fetching payments:', error);
        }
    }

    /**
     * Maneja el cambio de enfoque del botón y filtra los servicios no reservados según la categoría seleccionada.
     *
     * Params: 
     *   - index: El índice del botón en foco.
     *   - category: La categoría seleccionada.
     */
    const handleFocusButton = (index, category) => {
        setFocus(index);

        if (category == "Pagos Realizados") {
            setFilterPayments(payments.payer);
        } else {
            setFilterPayments(payments.payee);
        }
    }

    return (
        <div className="container">
            <div className="content-wo-footer alice-content">
                <div className="header-bar blue-bar">
                    <button className="button-bar blue-bar-title" size="icon" variant="ghost" onClick={() => navigate("/profile")}>
                        <Icon className='icon-bar back-icon-bar' icon="material-symbols:arrow-back-ios" />
                    </button>
                    <h1 className="title-bar blue-bar-title">Historial de Pagos</h1>
                    <button className="disabled-button" disabled></button>
                </div>
                <div className='nav-bar'>
                    <div className='nav-bar-elements'>
                        <button className={'nav-bar-element ' + (focus == 0 && "focus")} onClick={() => handleFocusButton(0, "Pagos Realizados")}>Pagos Realizados</button>
                        <button className={'nav-bar-element ' + (focus == 1 && "focus")} onClick={() => handleFocusButton(1, "Pagos Recibidos")}>Pagos Recibidos</button>
                    </div>
                </div>
                <div className='white-container'>
                    {filterPayments && (
                        <>
                            {filterPayments.map((payment, index) => {
                                return (
                                    <div className='service' key={index}>
                                        {payment.payee_fullName ? (
                                            <div className='img-service-container'><img src={payment.payee_avatar} className="img-profile" /></div>
                                        ) : (
                                            <div className='img-service-container'><img src={payment.payer_avatar} className="img-profile" /></div>
                                        )}

                                        <div className='service-info'>
                                            <div>
                                                <p className='title-user'>Pago Aceptado</p>
                                                {payment.payee_fullName ? (
                                                    <p className='job-user'>Pagado a: {payment.payee_fullName}</p>
                                                ) : (
                                                    <p className='job-user'>Pagado por: {payment.payer_fullName}</p>
                                                )}
                                                <p className='job-user'>{payment.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};