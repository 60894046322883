import React, { useState, useEffect } from 'react';
import Loader from './Loader';
import Slider from './Slider';
import { Icon } from '@iconify/react';
import './css/Avatar.css';

export default function Avatars({ setOpenModal, userData, setUserData, setUpdatingAvatar }) {
    const token = localStorage.getItem("cookie_token");
    const [avatars, setAvatars] = useState(null);
    const [focus, setFocus] = useState(0);
    const [image, setImage] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);

    /**
     * Realiza una petición API para recuperar todos los avatares disponibles.
     */
    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + "/api/avatars", {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setAvatars(data.avatars);
            });

    }, []);

    /**
     * Establece el avatar del usuario.
     *
     * Params: 
     * - avatarUrl (string) - URL del nuevo avatar a establecer.
     * Return: Ninguno
     */
    const setAvatar = (avatarUrl) => {
        setUpdatingAvatar(true);
        setOpenModal(false);

        let formDataUser = new FormData();
        formDataUser.append("avatar_url", avatarUrl);
        fetch(process.env.REACT_APP_API_URL + "/api/change-avatar", {
            method: "POST",
            body: formDataUser,
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (!data.error) {
                    const currentUserData = { ...userData };
                    currentUserData.avatar = avatarUrl;
                    setUserData(currentUserData);
                    localStorage.setItem("avatar", avatarUrl);
                }
                setUpdatingAvatar(false);
            });
    }

    /**
     * Maneja el cambio de imagen seleccionada por el usuario.
     *
     * Params: 
     * - event (objeto Event) - Evento que desencadena el cambio de imagen.
     * Return: Ninguno
     */
    const handleImageChange = (event) => {
        const image = event.target.files[0];
        const ownPhotoBox = document.querySelector('.own-photo-box');

        if (image) {
            const reader = new FileReader();
            reader.onload = function (e) {
                ownPhotoBox.style.backgroundImage = `url('${e.target.result}')`;
                ownPhotoBox.classList.add('with-image');
                setSelectedImage(image);
            }
            reader.readAsDataURL(image);
        }
    };

    /**
     * Sube la imagen seleccionada por el usuario al servidor.
     */
    const uploadImage = async () => {
        const formData = new FormData();
        formData.append('image', selectedImage);
        console.log(selectedImage);

        try {
            fetch(process.env.REACT_APP_API_URL + "/api/upload-image", {
                method: "POST",
                body: formData,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    setAvatar(data.imageUrl);
                });
        } catch (error) {
            console.error('Error de red:', error);
        }
    };

    return (
        <div className="modal-bg">
            <div className="modal">
                <div>
                    <div><h3>Seleccionar foto</h3></div>
                    <p>
                        Selecciona tu avatar o sube una foto.
                    </p>
                    <div className='nav-bar avatar-nav'>
                        <div className='nav-bar-elements'>
                            <button className={'nav-bar-element ' + (focus == 0 && "focus")} onClick={() => setFocus(0)}>Avatar</button>
                            <button className={'nav-bar-element ' + (focus == 1 && "focus")} onClick={() => setFocus(1)}>Tu foto</button>
                        </div>
                    </div>
                </div>
                {focus == 0 ? (
                    avatars ? (
                        <div className='fade-in-animation'>
                            <Slider images={avatars} click={true} clickFunction={setAvatar} />
                        </div>
                    ) : (
                        <Loader />
                    )
                ) : (
                    <>
                        <div class="own-photo-container">
                            <div class="own-photo-box">
                            </div>
                            <div class="own-photo-box-text">
                                <Icon icon="tabler:photo" className="own-photo-icon" />
                                <span class="own-photo-text">Arrastra y suelta tu foto aquí</span>
                                <input class="own-photo-input" id="modal-avatar-upload" type="file" onChange={handleImageChange} />
                                <label class="own-photo-label" for="modal-avatar-upload">Seleccionar archivo</label>
                            </div>

                        </div>
                        {selectedImage != null && (
                            <div className='select-avatar'>
                                <button onClick={() => uploadImage()}>Seleccionar Imagen</button>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div >
    );
}
