import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import "./css/GeneralStyles.css";
import "./css/Login.css";

export default function ForgotPassword() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');

    /**
     * Envía una solicitud para restablecer la contraseña.
     * 
     * Params: e (Event) - El evento del formulario.
     * Return: Ninguno.
     */
    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('email', email);

        fetch(`${process.env.REACT_APP_API_URL}/api/forgot-password`, {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
            });
    };

    return (
        <div className='container'>
            <div className="session">
                <form className="form-container" onSubmit={handleSubmit}>
                    <p className='log-in-text'>Introduce tu correo electrónico para reiniciar la contraseña</p>

                    <div className="input-form">
                        <Icon icon="tabler:mail" className='icon' />
                        <input
                            type="text"
                            className="input"
                            placeholder="Introduce tu email"
                            id='email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>

                    <div className='forgot-buttons-container'>
                        <button type="submit" className="forgot-button login-button">Enviar</button>
                    </div>
                </form>
            </div>
        </div>
    );
};
