import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function AuthAdmin() {
    const navigate = useNavigate();
    const token = localStorage.getItem("cookie_token");
    const [isAdmin, setIsAdmin] = useState(false);

    /**
     * Efecto que verifica si el usuario es administrador.
     */
    useEffect(() => {
        isAdminUser();
    }, []);

    /**
     * Verifica si el usuario es administrador.
    */
    function isAdminUser() {
        try {
            fetch(process.env.REACT_APP_API_URL + "/api/is-admin", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    if (data) {
                        setIsAdmin(data);
                    } else {
                        navigate("/profile");
                    }
                })
                .catch((error) => {
                    navigate("/profile");
                });
        } catch (error) {
            navigate("/profile");
        }
    }

    return isAdmin;
};