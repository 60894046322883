import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import "./css/GeneralStyles.css";
import "./css/Dashboard.css";
import Footer from '../components/FooterMenu';
import AuthCookie from '../components/AuthCookie';
import { useSocket } from "../services/socket";
import SearchServices from './Services';

export default function Dashboard() {
    const token = localStorage.getItem('cookie_token');
    const avatar = localStorage.getItem('avatar');
    const fullName = localStorage.getItem('fullName');
    const navigate = useNavigate();
    const { notNoLeidas } = useSocket();
    const [topServices, setTopServices] = useState([]);
    const [loadServices, setLoadServices] = useState(false);

    /**
     * Realiza una búsqueda de todos los servicios al cargar el componente.
     */
    useEffect(() => {
        searchAllServices();
    }, []);

    /**
     * Realiza una búsqueda de todos los servicios disponibles.
     */
    const searchAllServices = () => {
        fetch(process.env.REACT_APP_API_URL + "/api/search-all-services", {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                const combinedData = data.services.map(service => {
                    const category = data.servicesCategory.find(cat => cat.user_id === service.user_id);
                    return {
                        ...service,
                        categories: category ? category.categories.split(",") : []
                    };
                });
                data = combinedData.sort((a, b) => b.user.received_ratings_count - a.user.received_ratings_count);
                setTopServices(data);
                setLoadServices(true);
            });
    };

    const [categories, setCategories] = useState([
        {
            name: "Nutricionista",
            icon: "tabler:meat",
            path: () => navigate("/services", { state: { indexFocus: 1 } })
        },
        {
            name: "Guardería de día",
            icon: "tabler:sunset-2",
            path: () => navigate("/services", { state: { indexFocus: 2 } })
        },
        {
            name: "Educación Canina",
            icon: "tabler:school",
            path: () => navigate("/services", { state: { indexFocus: 3 } })
        },
        // {
        //     name: "Hospital Veterinario",
        //     icon: "tabler:building-hospital"
        // },
        {
            name: "Emergencias",
            icon: "tabler:ambulance",
            path: () => navigate("/emergency-page")
        },
        {
            name: "Más...",
            icon: "tabler:circle-plus",
            path: () => navigate("/emergency-page")
        }
    ]);

    /**
     * Traduce una categoría al idioma especificado.
     * 
     * Params: categoria (string) - La categoría a traducir.
     * Return: La categoría traducida.
     */
    function traducirCategoria(categoria) {
        switch (categoria) {
            case "Caregiver":
                return "Cuidador";
            case "Nutritionist":
                return "Nutricionista";
            case "Educator":
                return "Educador";
            default:
                return categoria;
        }
    }

    return (
        <div className='container dashboard-container'>
            <AuthCookie />
            <div className='alice-content content'>
                <div className='first-element'>
                    <div className='info-user'>
                        <img className='img-profile' src={avatar} onClick={() => navigate("/profile")}></img>
                        <div className='text-info-user'>
                            <p className='title-info-user'>Bienvenido</p>
                            <p className='name-info-user'>{fullName}</p>
                        </div>
                        <div className='notification-bell' onClick={() => navigate("/notifications")}>
                            <Icon icon="tabler:bell-filled" className='icon-bell' />
                            {notNoLeidas != 0 && (
                                <div className='notification-point'><p>{notNoLeidas}</p></div>
                            )}
                        </div>
                    </div>
                    {/* <div className='search-container'>
                        <div className="group-search">
                            <input className="input-search" type="search" placeholder="Buscar Servicios" />
                            <Icon icon="tabler:search" className="icon-search" />
                        </div>
                    </div> */}
                </div>
                <div className='categories'>
                    <div className='header-categories'>
                        <p className='title-categories'>Categorías</p>
                        {/* <button className='title-categories button-categories'>Mostrar Todas</button> */}
                    </div>
                    <div className='categories-container'>
                        {categories.map((category, index) => {
                            return (
                                <div className='category category-dashboard' key={index} onClick={category.path}>
                                    <Icon icon={category.icon} className="icon-category" />
                                    <p className='title-category'>{category.name}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className='categories'>
                    <div className='header-categories'>
                        <p className='title-categories'>Top Servicios</p>
                        {/* <button className='title-categories button-categories'>Mostrar Todas</button> */}
                    </div>
                    <div className='list-services'>
                        {loadServices && topServices.map((service, index) => {
                            return (
                                <div className='service top-service' key={index} onClick={() => navigate("/other-profile", { state: { id: service.user.id } })}>
                                    <div className='img-service-container'><img src={service.user.avatar} className="img-profile" /></div>
                                    <div className='service-info'>
                                        <div>
                                            <p className='title-user'>{service.user.fullName}</p>
                                            <p className='job-user'>
                                                {service.categories.map((category, index) => {
                                                    if (index == 0) {
                                                        return traducirCategoria(category)
                                                    } else {
                                                        return ", " + traducirCategoria(category)
                                                    }
                                                })}
                                            </p>
                                        </div>
                                        <div className='rating'>
                                            <Icon icon="tabler:star-filled" className='img-rating' />
                                            <p className=''>{Math.trunc(service.user.average_rating * 10) / 10} ({service.user.received_ratings_count} Rewiews)</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                        }
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    );
};
