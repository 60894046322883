import React, { useEffect, useState, useCallback } from 'react';
import "./css/FormComponent.css";
import TimePicker from "./TimePicker.js";
import { Icon } from '@iconify/react';
import { Marker, InfoWindow, StandaloneSearchBox } from '@react-google-maps/api';

export default function FormComponent({ inputType, variable, setVariable, setShowModal }) {
    const [returnVariable, setReturnVariable] = useState('');
    const [title, setTitle] = useState('');
    const [selectedDays, setSelectedDays] = useState([false, false, false, false, false, false, false]);
    const [selectedHours, setSelectedHours] = useState({});
    const [placeholder, setPlaceHolder] = useState('');
    const [clipboard, setClipboard] = useState(null);
    const days = [
        {
            type: "Monday",
            translate: "LUN",
        },
        {
            type: "Tuesday",
            translate: "MAR",
        },
        {
            type: "Wednesday",
            translate: "MIE",
        },
        {
            type: "Thursday",
            translate: "JUE",
        },
        {
            type: "Friday",
            translate: "VIE",
        },
        {
            type: "Saturday",
            translate: "SAB",
        },
        {
            type: "Sunday",
            translate: "DOM",
        }
    ];

    const categories = [{
        type: "Caregiver",
        translate: "Cuidador",
    }, {
        type: "Nutritionist",
        translate: "Nutricionista",
    }, {
        type: "Educator",
        translate: "Educador",
    }];

    const animals = [{
        type: "Dog",
        translate: "Perro",
    }, {
        type: "Cat",
        translate: "Gato",
    }, {
        type: "Turtle",
        translate: "Tortuga",
    }, {
        type: "Rabbit",
        translate: "Conejo",
    }];

    /**
     * Maneja el cambio de estado de un día específico en la lista de días seleccionados.
     *
     * Params: 
     * - index (number) - Índice del día en la lista de días seleccionados.
     * Return: Ninguno
     */
    const handleDayToggle = (index) => {
        const updatedDays = [...selectedDays];
        updatedDays[index] = !updatedDays[index];
        setSelectedDays(updatedDays);
    };

    /**
     * Maneja el cambio de hora para un día específico en la lista de horas seleccionadas.
     *
     * Params: 
     * - day (string) - Día para el cual se está cambiando la hora.
     * - type (string) - Tipo de hora que se está cambiando (inicio o fin).
     * - value (string) - Nuevo valor de la hora.
     * - slotIndex (number) - Índice del intervalo horario en la lista de horas seleccionadas para el día dado.
     * Return: Ninguno
     */
    const handleHourChange = (day, type, value, slotIndex) => {
        setSelectedHours((prevSelectedHours) => {
            const updatedHours = {
                ...prevSelectedHours,
                [day]: prevSelectedHours[day].map((timeSlot, index) => {
                    return index === slotIndex
                        ? { ...timeSlot, [type]: value }
                        : timeSlot;
                })
            };
            setReturnVariable(updatedHours);
            return updatedHours;
        });
    };

    /**
     * Maneja la adición de un nuevo intervalo horario para un día específico en la lista de horas seleccionadas.
     *
     * Params: 
     * - day (string) - Día para el cual se está añadiendo un nuevo intervalo horario.
     * Return: Ninguno
     */
    const handleAddTimeSlot = (day) => {
        setSelectedHours(prevSelectedHours => {
            const updatedDaySlots = [
                ...(prevSelectedHours[day] || []),
                { start: "00:00 am", end: "00:00 am" }
            ];
            setReturnVariable(updatedDaySlots);
            return {
                ...prevSelectedHours,
                [day]: updatedDaySlots
            };
        });
    };

    /**
     * Maneja la eliminación de un intervalo horario para un día específico en la lista de horas seleccionadas.
     *
     * Params: 
     * - day (string) - Día para el cual se está eliminando un intervalo horario.
     * - slotIndex (number) - Índice del intervalo horario que se va a eliminar en la lista de horas seleccionadas para el día dado.
     * Return: Ninguno
     */
    const handleRemoveTimeSlot = (day, slotIndex) => {
        setSelectedHours((prevSelectedHours) => {
            const updatedSelectedHours = {
                ...prevSelectedHours,
                [day]: prevSelectedHours[day].filter((_, index) => index !== slotIndex)
            };
            setReturnVariable(updatedSelectedHours);
            return updatedSelectedHours;
        });
    };

    /**
     * Configura variables y títulos basados en el tipo de entrada en un efecto de React.
     */
    useEffect(() => {
        setReturnVariable(variable);
        switch (inputType) {
            case "category": setTitle("Selecciona tu categoría");
                break;
            case "description": setTitle("Selecciona tu descripción");
                setPlaceHolder("Ej: Soy un Cuidador que lleva...");
                break;
            case "animal-type": setTitle("Selecciona el tipo de Animal");
                break;
            case "schedule": setTitle("Horas semanales");
                if (variable == {}) {
                    setReturnVariable({});
                } else {
                    setSelectedDays(getDaysWithHours(variable))
                    setSelectedHours(variable);
                }
                break;
            case "place": setTitle("Selecciona tu ciudad");
                setPlaceHolder("Ej: Martorell y alrededores");
                break;
            case "max-pets": setTitle("Selecciona el máximo de mascotas");
                setReturnVariable(variable);
                break;
            case "price": setTitle("Selecciona el precio")
                setReturnVariable(variable);
                break;
        }
    }, []);

    /**
     * Obtiene un arreglo que indica qué días tienen horas asignadas en un objeto de horas dado.
     *
     * Params:
     * - hoursObject (object) - Objeto que contiene horas asignadas para cada día de la semana.
     * Return: Un arreglo de booleanos donde cada índice representa un día de la semana y el valor indica si ese día tiene horas asignadas.
     */
    function getDaysWithHours(hoursObject) {
        const daysWithHours = [false, false, false, false, false, false, false];

        for (const day in hoursObject) {
            const index = getDayIndex(day);
            if (hoursObject[day].length > 0) {
                daysWithHours[index] = true;
            }
        }

        return daysWithHours;
    }

    /**
     * Obtiene el índice correspondiente a un día de la semana dado.
     *
     * Params:
     * - day (string) - Día de la semana.
     * Return: El índice correspondiente al día de la semana en el arreglo de días de la semana (0 para lunes, 1 para martes, ..., 6 para domingo).
     */
    function getDayIndex(day) {
        switch (day) {
            case "Monday":
                return 0;
            case "Tuesday":
                return 1;
            case "Wednesday":
                return 2;
            case "Thursday":
                return 3;
            case "Friday":
                return 4;
            case "Saturday":
                return 5;
            case "Sunday":
                return 6;
            default:
                return -1;
        }
    }

    /**
     * Maneja el cambio de valor en un evento.
     *
     * Params:
     * - event (object) - Evento que desencadena el cambio de valor.
     * Return: Ninguno.
     */
    const handleChange = (event) => {
        setReturnVariable(event.target.value);
    };

    /**
     * Guarda el valor de retorno en la variable principal según el tipo de entrada.
     */
    const handleSave = () => {
        setVariable(returnVariable);
        if (inputType == "place") {
            setVariable(document.getElementById('search-box').value);
        } else if (inputType == "schedule") {
            let variableChanged = {};
            for (const key in returnVariable) {
                const dayIndex = getDayIndex(key);
                if (dayIndex !== -1 && selectedDays[dayIndex]) {
                    variableChanged[key] = returnVariable[key];
                }
            }
            console.log(variableChanged);
            setVariable(variableChanged);
        } else {
            setVariable(returnVariable);
        }
        setShowModal(false);
    };

    /**
     * Decrementa el valor de la variable de retorno en 1, asegurándose de que no sea menor que 0.
     */
    const decrementReturnVariable = () => {
        setReturnVariable(prevVariable => (prevVariable - 1 < 0 ? 0 : prevVariable - 1));
    };

    /**
     * Maneja el evento de pegado de datos en un día específico del horario.
     *
     * Params: 
     *   - day: El día al que se está pegando la información.
     * Return: Ninguno.
     */
    const handlePaste = (day) => {
        if (clipboard != null) {
            setSelectedHours((prevSelectedHours) => {
                const updatedSelectedHours = {
                    ...prevSelectedHours,
                    [day]: clipboard
                };
                setReturnVariable(updatedSelectedHours);
                return updatedSelectedHours;
            });
        }
    }

    return (
        <div>
            <h2 className='form-title'>{title}</h2>
            <br></br>
            {inputType == "category" ? (
                <select className='input' value={returnVariable} onChange={handleChange}>
                    <option value="">Selecciona una categoría</option>
                    {categories.map((categoria) => (
                        <option key={categoria.type} value={categoria.type}>
                            {categoria.translate}
                        </option>
                    ))}
                </select>
            ) : inputType == "animal-type" ? (
                <select className='input' value={returnVariable} onChange={handleChange}>
                    <option value="">Selecciona una categoría</option>
                    {animals.map((animal) => (
                        <option key={animal.type} value={animal.type}>
                            {animal.translate}
                        </option>
                    ))}
                </select>
            ) : inputType == "schedule" ? (
                <div className='container-schedule'>
                    {days.map((day, index) => (
                        <div className="container-range-hours" key={day.type}>
                            <div className='range-hours-title'>
                                <div className={`range-hour-day ${!selectedDays[index] && selectedHours[day.type] != 0 ? "" : "margin"}`}>

                                    <input type="checkbox" checked={selectedDays[index]} onChange={() => handleDayToggle(index)} />
                                    <p>{day.translate}</p>
                                </div>
                                <div>
                                    {selectedDays[index] ? (
                                        <>
                                            <button className='button-range-hour' onClick={() => handleAddTimeSlot(day.type)}><Icon icon="tabler:plus" /></button>
                                            <button className='button-range-hour' onClick={() => setClipboard(selectedHours[day.type])}><Icon icon="tabler:copy" /></button>
                                            <button className='button-range-hour' onClick={() => handlePaste(day.type)}><Icon icon="tabler:clipboard" /></button>
                                        </>
                                    ) : (
                                        <>
                                            <button className='button-range-hour disabled'><Icon icon="tabler:plus" /></button>
                                            <button className='button-range-hour disabled'><Icon icon="tabler:copy" /></button>
                                            <button className='button-range-hour disabled'><Icon icon="tabler:clipboard" /></button>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div>
                                <div>
                                    {selectedDays[index] ? (
                                        <>
                                            {selectedHours[day.type]?.map((timeSlot, slotIndex) => (
                                                <div className='range-hours' key={slotIndex} >
                                                    <TimePicker
                                                        value={timeSlot.start || ''}
                                                        onChange={(value) => handleHourChange(day.type, 'start', value, slotIndex)}
                                                    />
                                                    <p>-</p>
                                                    <TimePicker
                                                        value={timeSlot.end || ''}
                                                        onChange={(value) => handleHourChange(day.type, 'end', value, slotIndex)}
                                                    />
                                                    <button onClick={() => handleRemoveTimeSlot(day.type, slotIndex)} className='close-button-range'><Icon icon="tabler:x" /></button>
                                                </div>
                                            ))}
                                        </>
                                    ) : (
                                        <p>Unavailable</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))
                    }
                </div >
            ) : inputType == "description" ? (
                <div>
                    <input type="text" value={returnVariable} className='input' min="0" placeholder={placeholder} onChange={handleChange} />
                </div>
            ) : inputType == "place" ? (
                <StandaloneSearchBox>
                    <input className="input" id="search-box" value={returnVariable} onChange={(e) => { handleChange(e) }} type="search" placeholder={placeholder} />
                </StandaloneSearchBox>
            ) : inputType == "price" || inputType == "max-pets" ? (
                <div className='input-number'>
                    <button className="operation-button" onClick={() => setReturnVariable(prevVariable => prevVariable + 1)}>
                        <Icon icon="tabler:plus" />
                    </button>
                    <input type="number" value={returnVariable} className='input' min="0" max="10" onChange={handleChange} />
                    <button className="operation-button" onClick={() => decrementReturnVariable()}>
                        <Icon icon="tabler:minus" />
                    </button>
                </div>
            ) : (<></>)
            }
            <button className="main-button" onClick={() => handleSave()}>Guardar</button>
        </div >
    );
};