import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import FormComponent from '../components/FormComponent';
import './css/AddService.css';

export default function AddService() {
    const token = localStorage.getItem("cookie_token");
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [description, setDescription] = useState('');
    const [inputForm, setInputForm] = useState(null);
    const [category, setCategory] = useState('');
    const [animalType, setAnimalType] = useState('');
    const [schedule, setSchedule] = useState('');
    const [price, setPrice] = useState(0);
    const [municipality, setMunicipality] = useState('');
    const [homeService, setHomeService] = useState(false);
    const [maxPets, setMaxPets] = useState(0);

    /**
     * Envía un formulario para agregar un nuevo servicio.
     * 
     * Params: e (Event) - El evento del formulario.
     * Return: Ninguno.
     */
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('category', category);
        formData.append('description', description);
        formData.append('animal_type', animalType);
        formData.append('schedule', JSON.stringify(schedule));
        formData.append('price', price);
        formData.append('municipality', municipality);
        formData.append('home_service', homeService ? '1' : '0');
        formData.append('max_pets', maxPets);

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/api/add-service", {
                method: "POST",
                body: formData,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                },
            });

            const data = await response.json();
            if (data.error == true) {
                console.log(data);
                setErrorModal(true);
            } else {
                navigate("/profile");
            }
        } catch (error) {
            console.error("Error al agregar el servicio:", error);
        }
    };

    /**
     * Establece el contenido del modal de entrada y lo muestra.
     * 
     * Params: 
     * - inputType (string) - El tipo de entrada.
     * - variable (any) - La variable asociada al tipo de entrada.
     * - setVariable (function) - Función para establecer el valor de la variable.
     * - placeholder (string) - El marcador de posición para el campo de entrada.
     * 
     * Return: Ninguno.
     */
    const setInputModal = (inputType, variable, setVariable, placeholder) => {
        setInputForm(<FormComponent inputType={inputType} variable={variable} setVariable={setVariable} setShowModal={setShowModal} placeholder={placeholder} />);
        setShowModal(true);
    }

    return (
        <div className="container">
            <div className="content-wo-footer">
                <div className="header-bar cian-bar">
                    <button className="button-bar blue-bar-title" size="icon" variant="ghost" onClick={() => navigate("/my-services")}>
                        <Icon className='icon-bar back-icon-bar' icon="material-symbols:arrow-back-ios" />
                    </button>
                    <h1 className="title-bar blue-bar-title">Crear Servicio</h1>
                    <button className="button-bar blue-bar-title" size="icon" variant="ghost" onClick={() => navigate("/dashboard")}>
                        <Icon className='icon-bar' icon="tabler:home-2" />
                    </button>
                </div>
                <div className="profile-element">
                    <div className="image-profile-element">
                        <img
                            alt="Avatar"
                            className="image-profile"
                            src={"https://source.unsplash.com/random"}
                        />
                        <button className="button-profile-element" size="icon" variant="ghost" onClick={() => console.log("hola")}>
                            <Icon icon="tabler:edit" className='icon-edit' />
                            <span className="sr-only">Editar</span>
                        </button>
                    </div>
                    <div className="name-container">
                    </div>
                </div>
                <div className='config-buttons-container'>
                    <div className='config-general'>
                        <div className='title-config'><h2>General</h2></div>
                        <button className="button-config size-not-effect" onClick={() => setInputModal("category", category, setCategory)}>
                            <div className='element-button-config'>
                                <Icon className='icon-config' icon="tabler:paw" />
                                <p>Categoría</p>
                            </div>
                            {category == "" ? (
                                <Icon icon="material-symbols:arrow-forward-ios" />
                            ) : (
                                <Icon icon="tabler:check" className='check' />
                            )}
                        </button>
                        <button className="button-config size-not-effect" onClick={() => setInputModal("description", description, setDescription)}>
                            <div className='element-button-config'>
                                <Icon className='icon-config' icon="tabler:text-caption" />
                                <p>Descripción</p>
                            </div>
                            {description == "" ? (
                                <Icon icon="material-symbols:arrow-forward-ios" />
                            ) : (
                                <Icon icon="tabler:check" className='check' />
                            )}
                        </button>
                        <button className="button-config size-not-effect" onClick={() => setInputModal("animal-type", animalType, setAnimalType)}>
                            <div className='element-button-config'>
                                <Icon className='icon-config' icon="tabler:dog" />
                                <p>Tipo de animal</p>
                            </div>
                            {animalType == "" ? (
                                <Icon icon="material-symbols:arrow-forward-ios" />
                            ) : (
                                <Icon icon="tabler:check" className='check' />
                            )}
                        </button>
                        <button className="button-config size-not-effect" onClick={() => setInputModal("max-pets", maxPets, setMaxPets)}>
                            <div className='element-button-config'>
                                <Icon className='icon-config' icon="tabler:dog" />
                                <p>Maximo de Mascotas</p>
                            </div>
                            {maxPets == "" ? (
                                <Icon icon="material-symbols:arrow-forward-ios" />
                            ) : (
                                <Icon icon="tabler:check" className='check' />
                            )}
                        </button>
                    </div>
                </div>
                <div className='config-buttons-container'>
                    <div className='config-general'>
                        <div className='title-config'><h2>Lugar y horarios</h2></div>
                        <button className="button-config size-not-effect" onClick={() => setInputModal("schedule", schedule, setSchedule)}>
                            <div className='element-button-config'>
                                <Icon className='icon-config' icon="tabler:calendar" />
                                <p>Dias de la semana</p>
                            </div>
                            {schedule == "" ? (
                                <Icon icon="material-symbols:arrow-forward-ios" />
                            ) : (
                                <Icon icon="tabler:check" className='check' />
                            )}
                        </button>
                        <button className="button-config size-not-effect" onClick={() => setInputModal("place", municipality, setMunicipality)}>
                            <div className='element-button-config'>
                                <Icon className='icon-config' icon="tabler:location" />
                                <p>Lugar</p>
                            </div>
                            {municipality == "" ? (
                                <Icon icon="material-symbols:arrow-forward-ios" />
                            ) : (
                                <Icon icon="tabler:check" className='check' />
                            )}
                        </button>
                        <button className="button-config size-not-effect" onClick={() => setHomeService(!homeService)}>
                            <div className='element-button-config'>
                                <Icon className='icon-config' icon="tabler:home" />
                                <p>Servicio a domicilio</p>
                            </div>
                            <input type='checkbox' value={homeService} checked={homeService} onChange={() => setHomeService(!homeService)} />
                        </button>
                        <button className="button-config size-not-effect" onClick={() => setInputModal("price", price, setPrice)}>
                            <div className='element-button-config'>
                                <Icon className='icon-config' icon="tabler:coin-euro" />
                                <p>Precio €/hora</p>
                            </div>
                            {price == "" ? (
                                <Icon icon="material-symbols:arrow-forward-ios" />
                            ) : (
                                <Icon icon="tabler:check" className='check' />
                            )}
                        </button>
                    </div>
                    <button className='add-service-button' onClick={handleSubmit}>Añadir el servicio</button>
                </div>
            </div>
            {showModal && (
                <div className="modal-bg">
                    <div className="modal">
                        {inputForm}
                    </div>
                </div>
            )}
            {errorModal && (
                <div className="modal-bg" onClick={() => setErrorModal(false)}>
                    <div className="modal">
                        <h3>Completa todos los campos correctamente</h3>
                    </div>
                </div>
            )}
        </div>
    );
}
