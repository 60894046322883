import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import './css/GeneralStyles.css';
import Loader from '../components/Loader';

export default function ServiceOffer({ setMenu }) {
    const navigate = useNavigate();
    const token = localStorage.getItem("cookie_token");
    const [servicesOffered, setServicesOffered] = useState(false);
    const [loadContent, setLoadContent] = useState(false);
    const [serviceIdToDelete, setServiceIdToDelete] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [services, setServices] = useState([]);

    /**
     * Cuando se inicia el componente hace una busqueda de servicios
     */
    useEffect(() => {
        searchServices();
    }, []);

    /**
     * Realiza una búsqueda de los servicios del usuario.
     */
    const searchServices = () => {
        fetch(process.env.REACT_APP_API_URL + "/api/search-my-services", {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.services.length == 0) {
                    toggleServicesOffered(false);
                } else {
                    setLoadContent(true);
                }
                setServices(data.services);
            });
    };

    const toggleServicesOffered = (status) => {
        setServicesOffered(status);
        setLoadContent(true);

        let formData = new FormData();
        formData.append("status", status == true ? "1" : "0");
        fetch(process.env.REACT_APP_API_URL + "/api/toggle-service", {
            method: "POST",
            body: formData,
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setServicesOffered(data.status == 0 ? false : true);
            });
    };

    const handleDelete = (serviceId) => {
        setServiceIdToDelete(serviceId);
        setShowModal(true);
    };

    const confirmDelete = () => {
        fetch(process.env.REACT_APP_API_URL + "/api/delete-service/" + serviceIdToDelete, {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setServices(services.filter(service => service.id !== serviceIdToDelete));
                if (services.filter(service => service.id !== serviceIdToDelete).length == 0) {
                    toggleServicesOffered(false);
                }
                setShowModal(false);
            })
            .catch((error) => console.error("Error al eliminar el servicio:", error));
    };

    const cancelDelete = () => {
        setShowModal(false);
    };


    return (
        <div className="container">
            <div className="content-wo-footer">
                <div className="header-bar white-bar">
                    <button className="button-bar back-button-bar" size="icon" variant="ghost" onClick={() => navigate("/profile")}>
                        <Icon className='icon-bar back-icon-bar' icon="material-symbols:arrow-back-ios" />
                    </button>
                    <h1 className="title-bar white-bar-title">Mis servicios</h1>
                    <button className="disabled-button" disabled></button>
                </div>
                <div className='white-container'>
                    {loadContent ? (
                        <>
                            {/* 
                                <div className="switch-container">
                                        <h2>¿Ofrecer servicios?</h2>
                                        <label className="switch">
                                            <input type="checkbox" value={servicesOffered} onClick={() => toggleServicesOffered(true)} />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                            */}
                            <table className="mascotas-table">
                                <thead>
                                    <tr>
                                        <th>Categoria</th>
                                        <th>Días</th>
                                        <th>A domicilio</th>
                                        <th>Precio</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {services.map(service => (
                                        <tr key={service.id}>
                                            <td>{service.category}</td>
                                            <td>{service.day_of_week}</td>
                                            <td>{service.home_service == 1 ? 'Sí' : 'No'}</td>
                                            <td>{service.price}</td>
                                            <td>
                                                <div className='mascota-buttons-container'>
                                                    <button className='mascota-button edit' onClick={() => navigate("/edit-service", { state: { service: service } })}><Icon icon="tabler:pencil" /></button>
                                                    <button className='mascota-button delete' onClick={() => handleDelete(service.id)}><Icon icon="tabler:trash" /></button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="form-container">
                                <button className='submit-button' onClick={() => { navigate("/add-service") }}>Crear Servicio</button>
                            </div>
                            {showModal && (
                                <div className="modal-bg">
                                    <div className="modal">
                                        <h2>¿Estás seguro que deseas eliminar el servicio?</h2>
                                        <div className="modal-buttons">
                                            <button className="delete modal-button" onClick={confirmDelete}>Aceptar</button>
                                            <button className="edit modal-button" onClick={cancelDelete}>Cancelar</button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <Loader />
                    )}
                </div>
            </div>
        </div>
    );
}
