import { Icon } from '@iconify/react';

export default function BackCard({ service, user }) {
    return (service && (
        <div className='card'>
            <div className="card-profile" style={{ backgroundImage: `url(${user.avatar})` }}>
                <div className="card-content">
                    <h2 className="card-title">{user.name}</h2>
                </div>
            </div>
            <div className="card-divider">
                <div className='categories-contract'>
                    <div className='categories-container'>
                        <div className='category category-contract'>
                            <p className='title-category'>Tipo</p>
                            <p className='text-category'>{service.category}</p>
                        </div>
                        <div className='category category-contract'>
                            <p className='title-category'>Experiencia</p>
                            <p className='text-category'>3 años</p>
                        </div>
                        <div className='category category-contract'>
                            <p className='title-category'>A domicilio</p>
                            <p className='text-category'>{service.home_service == 1 ? "Si" : "No"}</p>
                        </div>
                    </div>
                </div>
                <div className='disponibility-container'>
                    <Icon icon="tabler:calendar-month" className='disponibility-icon' />
                    <p>{service.price} €</p>
                    <div className='rating'>
                        <Icon icon="tabler:star-filled" className='img-rating' />
                        <p>{(Math.round(user.average_rating * 10) / 10).toFixed(1)} ({user.received_ratings_count} Reviews)</p>
                    </div>
                    <div className='disponibility-button-container'>
                        <a href={`tel:${user.phone_number}`} className='disponibility-button'><Icon icon="tabler:phone-call" className='disponibility-button-icon' /></a>
                        <button className='disponibility-button'><Icon icon="tabler:brand-line" className='disponibility-button-icon' /></button>
                    </div>
                </div>
            </div>
            <div className="card-actions">
                <button className="card-actions-button card-actions-button-delete">
                    Next
                </button>
                <button className="card-actions-button card-actions-button-connect">
                    Connect
                </button>
            </div>
        </div>
    ))
}