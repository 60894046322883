import React, { useEffect, useState } from 'react';
import './css/ContractInfo.css';
import './css/GeneralStyles.css';
import { Icon } from '@iconify/react';
import { useNavigate, useLocation } from 'react-router-dom';
import WeekCalendar from '../components/WeekCalendar';
import PayPalButton from '../components/PayPalButton';
import { useSocket } from "../services/socket";

export default function Contract() {
    const { sendNotificacion } = useSocket();
    const token = localStorage.getItem("cookie_token");
    const fullName = localStorage.getItem("fullName");
    const location = useLocation();
    const navigate = useNavigate();
    const service = location.state?.service ? location.state.service : navigate("/services");
    const [contracted, setContracted] = useState(false);
    const [payed, setPayed] = useState(false);
    const [error, setError] = useState(false);
    const [activeHours, setActiveHours] = useState([]);
    const hours = [
        '09:00', '09:30', '10:00', '10:30',
        '11:00', '11:30', '12:00', '13:00',
        '13:30', '14:00', '14:30', '15:00',
        '15:30', '16:00', '16:30', '17:00'
    ];

    /**
     * Realiza un contrato de servicio. 
     */
    const contract = () => {
        const formData = new FormData();
        formData.append('service_id', service.id);

        fetch(process.env.REACT_APP_API_URL + "/api/contract-service", {
            method: "POST",
            body: formData,
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (!data.error) {
                    pay();
                } else {
                    setContracted(false);
                    setError(true);
                }
            });
    }

    /**
     * Realiza un pago por un servicio.
     */
    const pay = () => {
        const formData = new FormData();
        formData.append('payee_id', service.user.id);
        formData.append('service_id', service.id);
        formData.append('amount', service.price);
        formData.append('description', "Pago realizado por " + fullName + " para el servicio de " + service.category + " por una cantidad de " + service.price + "€");

        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().slice(0, 16);
        formData.append('payment_date', formattedDate);


        fetch(process.env.REACT_APP_API_URL + "/api/pay", {
            method: "POST",
            body: formData,
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setContracted(false);
                setPayed(true);
                sendNotificacion(data.payee_id);
            });
    }

    /**
     * Alterna la selección de una hora en la interfaz y actualiza el estado de las horas activas.
     *
     * Params:
     *   - index: El índice de la hora seleccionada.
     */
    const toggleHour = (index) => {
        const isActive = activeHours.includes(index);
        let updatedHours;

        if (isActive) {
            updatedHours = activeHours.filter(day => day !== index);
        } else {
            updatedHours = [...activeHours, index].sort((a, b) => a - b);
        }

        setActiveHours(updatedHours);
    };

    return (
        <div className="container">
            {service && (
                <div className="content-wo-footer">
                    <WeekCalendar />
                    <div className='line line-complete'></div>
                    <div className='white-container'>
                        <h3>Contrata el servicio de:</h3>
                        <div className='service'>
                            <div className='img-service-container'><img src={service.user.avatar} className="img-profile" /></div>
                            <div className='service-info'>
                                <div>
                                    <p className='title-user'>{service.user.fullName}</p>
                                    <p className='job-user'>{service.category}</p>
                                </div>
                                <div className='rating'>
                                    <Icon icon="tabler:star-filled" className='img-rating' />
                                    <p>{(Math.round(service.user.average_rating * 10) / 10).toFixed(1)} ({service.user.received_ratings_count} Rewiews)</p>
                                </div>
                            </div>
                        </div>
                        <div className="input-container">
                            <div className='days-container four-grid'>
                                {hours.map((day, index) => {
                                    const isActive = activeHours.includes(index + 1);
                                    return (
                                        <button key={index} type="button" value={index + 1} onClick={() => toggleHour(index + 1)} className={(isActive ? 'active switch-button' : 'switch-button')}>{day}</button>
                                    );
                                })}
                            </div>
                        </div>
                        <div className='line line-complete'></div>
                        <div className='observaciones'>
                            <h5>Observaciones</h5>
                            <input type="textarea"></input>
                        </div>
                        <div className='contract-button-container'>
                            <button className='contracted contracted-button' onClick={() => setContracted(true)}>Contratar</button>
                        </div>
                    </div>
                </div>
            )}
            {contracted && (
                <div className="modal-bg">
                    <div className="modal">
                        <h2>Realizar Pago de {service.price} €</h2>
                        <p>Al hacer click en el siguiente boton se te redirigira para realizar el pago</p>
                        <PayPalButton price={service.price} contract={contract} />
                        {/* <div className="modal-div-center"><button className="modal-button modal-button-center" onClick={() => contract()}>Pagar</button></div> */}
                    </div>
                </div>
            )}
            {payed && (
                <div className="modal-bg">
                    <div className="modal">
                        <h2>Pago Recibido</h2>
                        <p>Haz click aquí para volver a la pagina principal</p>
                        <div className="modal-div-center"><button className="modal-button modal-button-center" onClick={() => navigate("/dashboard")}>Ir</button></div>
                    </div>
                </div>
            )}
            {error && (
                <div className="modal-bg">
                    <div className="modal">
                        <h2>Error! Este servicio ya ha sido reservado por otra persona</h2>
                        <p>Haz click aquí para volver a la pagina principal</p>
                        <div className="modal-div-center"><button className="modal-button modal-button-center" onClick={() => navigate("/dashboard")}>Ir</button></div>
                    </div>
                </div>
            )}
        </div>
    );
};