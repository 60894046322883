import React, { useState, useEffect, useRef } from 'react';
import './css/TopServiceModal.css';
import Card from '../components/Card';
import { Icon } from '@iconify/react';

export default function TopServiceModal({ user, checkTopService, closeTopModal }) {
    const token = localStorage.getItem("cookie_token");
    const [services, setServices] = useState(null);
    const [showTopService, setShowTopService] = useState(false);
    const [showCard, setShowCard] = useState(false);
    const cardRef = useRef(null);
    const [offsetX, setOffsetX] = useState(0);
    const [actualProfile, setActualProfile] = useState(null);

    /**
     * Realiza una búsqueda de servicios y muestra el servicio superior después de un retraso.
     */
    useEffect(() => {
        searchServices();
        setTimeout(() => {
            setShowTopService(true);
        }, 3000);
    }, []);

    /**
     * Realiza una búsqueda de servicios del usuario.
     */
    const searchServices = async () => {
        fetch(process.env.REACT_APP_API_URL + "/api/search-my-services", {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setServices(data.services);
            });
    };

    /**
     * Muestra la tarjeta del perfil seleccionado.
     *
     * Params: index (number) - El índice del perfil que se va a mostrar.
     * Return: Ninguno.
     */
    const viewCard = (index) => {
        setActualProfile(index);
        setShowCard(true);
    }

    return (
        <div className="modal-bg">
            <div className="modal rating-modal">
                <button className="close-button" onClick={() => closeTopModal(false)}>
                    <Icon icon="tabler:x" />
                </button>
                {!showCard ? !showTopService ? (
                    <div className="info">
                        <h1>Escoge el servicio que quieres que salga en cards</h1>
                        <p>Puedes volver a cambiarlo después</p>
                    </div>
                ) : (
                    <div className='my-top-service'>
                        <h1>Tus servicios</h1>
                        <p>Este servicio aparecera como targeta a otros usuarios</p>
                        <div className='list-top-service'>
                            {services && services.map((service, index) => {
                                return (
                                    <div key={index} className='item-top-service' onClick={() => viewCard(index)}>
                                        <p>{service.category}</p>
                                        <p>Para: {service.animal_type}</p>
                                        <p>{service.price} €</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                ) : (
                    <div className='top-card fade-in-animation'>
                        <Card service={services[actualProfile]} user={user} offsetX={offsetX} cardRef={cardRef} />
                        <div className="modal-buttons">
                            <button className="cancel modal-button" onClick={() => setShowCard(false)}>Atras</button>
                            <button className="rating-btn modal-button" onClick={() => checkTopService(services[actualProfile].id)}>Continuar</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}