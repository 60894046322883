import React, { useEffect, useState } from 'react';
import './css/ContractInfo.css';
import './css/GeneralStyles.css';
import RatingModal from '../components/RatingModal';
import { Icon } from '@iconify/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSocket } from "../services/socket";

export default function Notifications() {
    const token = localStorage.getItem("cookie_token");
    const location = useLocation();
    const navigate = useNavigate();
    const { notificaciones, getNotificaciones, deleteNotificacion, sendNotificacion } = useSocket();
    const [listNotificaciones, setListNotificaciones] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [indexNotModal, setIndexNotModal] = useState(false);
    const [startX, setStartX] = useState(null);
    const [isSliding, setIsSliding] = useState(false);

    /**
     * Actualiza la lista de notificaciones al montar el componente.
     */
    useEffect(() => {
        if (notificaciones.length == 0) {
            getNotificaciones();
        }
    }, []);

    /**
     * Actualiza la lista de notificaciones al cambiar el numero de notificaciones.
     */
    useEffect(() => {
        setListNotificaciones(notificaciones);
    }, [notificaciones]);

    /**
     * Finaliza un servicio específico.
     * 
     * Params: id (string) - El ID del servicio a finalizar.
     * Return: Ninguno.
     */
    const endService = (id) => {
        fetch(process.env.REACT_APP_API_URL + "/api/end-service/" + id, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                if (data.message == 1) {
                    getNotificaciones();
                    sendNotificacion(data.id);
                }
            });
    };

    /**
     * Establece los datos para mostrar en el modal.
     * 
     * Params: index (number) - El índice del elemento para el cual se mostrarán los datos en el modal.
     * Return: Ninguno.
     */
    const setDataModal = (index) => {
        setIndexNotModal(index);
        setShowModal(true);
    }

    /**
     * Establece la posición inicial del deslizamiento táctil y activa el estado de deslizamiento.
     * 
     * Params: e (TouchEvent) - Evento táctil que contiene información sobre el toque.
     *         index (number) - El índice del elemento en el que se inició el deslizamiento.
     * Return: Ninguno.
     */
    const handleTouchStart = (e, index) => {
        setStartX(e.touches[0].clientX);
        setIsSliding(true);
        setIndexNotModal(index);
    };

    /**
     * Maneja el movimiento táctil durante el deslizamiento.
     * 
     * Params: e (TouchEvent) - Evento táctil que contiene información sobre el movimiento.
     * Return: Ninguno.
     */
    const handleTouchMove = (e) => {
        if (!startX || !isSliding) return;

        const currentX = e.touches[0].clientX;
        const diff = currentX - startX;

        const slidingDiv = document.querySelectorAll('.slidableDiv')[indexNotModal];
        slidingDiv.style.transform = `translateX(${diff}px)`;
    };

    /**
     * Maneja el final del movimiento táctil durante el deslizamiento.
     */
    const handleTouchEnd = () => {
        setIsSliding(false);
        setStartX(null);

        const slidingDiv = document.querySelectorAll('.slidableDiv')[indexNotModal];
        const currentTranslation = parseInt(slidingDiv.style.transform.replace('translateX(', '').replace('px)', ''), 10);
        console.log(listNotificaciones[indexNotModal]);
        if (Math.abs(currentTranslation) > 100) {
            const updatedNotificaciones = [...listNotificaciones];
            updatedNotificaciones.splice(indexNotModal, 1);
            setListNotificaciones(updatedNotificaciones);
            deleteNotificacion(listNotificaciones[indexNotModal].id, listNotificaciones[indexNotModal].type);
            slidingDiv.style.transform = 'translateX(0)';
        } else {
            slidingDiv.style.transform = 'translateX(0)';
        }
    };

    return (
        <div className="container notify-container">
            <div className="content-wo-footer alice-content">
                <div className="header-bar white-bar">
                    <button className="button-bar back-button-bar" size="icon" variant="ghost" onClick={() => navigate("/dashboard")}>
                        <Icon className='icon-bar back-icon-bar' icon="material-symbols:arrow-back-ios" />
                    </button>
                    <h1 className="title-bar white-bar-title">Notificaciones</h1>
                    <button className="disabled-button" disabled></button>
                </div>
                <div className='transparent-container notifications-container'>
                    {listNotificaciones != false ? (
                        listNotificaciones.map((notificacion, index) => {
                            if (notificacion.type == "G") {
                                return (
                                    <div className='service slidableDiv' key={index}
                                        id="slidingDiv"
                                        onTouchStart={(e) => handleTouchStart(e, index)}
                                        onTouchMove={handleTouchMove}
                                        onTouchEnd={handleTouchEnd}
                                    >
                                        <div className='img-service-container'><img src={"https://e7.pngegg.com/pngimages/569/18/png-clipart-earth-cartoon-earth-sphere-cartoon.png"} className="img-profile" /></div>
                                        <div className='service-info'>
                                            <div>
                                                <p className='title-user'>GLOBAL</p>
                                                <p className='job-user'>{notificacion.text}</p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            } else {
                                return (
                                    <div className='service notify-w-button slidableDiv' key={index}
                                        id="slidingDiv"
                                        onTouchStart={(e) => handleTouchStart(e, index)}
                                        onTouchMove={handleTouchMove}
                                        onTouchEnd={handleTouchEnd}
                                    >
                                        <div className='img-service-container'><img src={notificacion.avatar} className="img-profile" /></div>
                                        <div className='service-info'>
                                            <div>
                                                <p className='title-user'>{notificacion.name}</p>
                                                <p className='job-user'>{notificacion.text}</p>
                                            </div>
                                        </div>
                                        {notificacion.type == "V" ? (
                                            <button className="button-notify" onClick={() => setDataModal(index)}>Valorar</button>
                                        ) : (
                                            <button className="button-notify" onClick={() => endService(notificacion.service_id)}>Aceptar</button>
                                        )}
                                    </div>
                                );
                            }
                        })
                    ) : (
                        <h3 className='not-notify fade-in-animation'> No hay notificaciones </h3>
                    )}
                </div>
                {showModal && (
                    <RatingModal
                        notificacion={notificaciones[indexNotModal]}
                        setShowModal={() => setShowModal()}
                        getNotificaciones={getNotificaciones}
                        ratingData={null} />
                )}
            </div>
        </div>
    );
};