import React from 'react';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import EmptyImage from '../images/emptyAddPet.png';

export default function AddPetEmpty() {
    const navigate = useNavigate();

    return (
        <div className='fade-in-animation'>
            <button className="pet-button back-pet-button" variant="secondary" onClick={() => navigate("/profile")}>
                <Icon className='icon-pet-button' icon="material-symbols:arrow-back-ios" />
            </button>
            <button className="add-pet-button" variant="secondary" onClick={() => navigate("/add-pet")}>
                <Icon icon="tabler:plus" />
            </button>
            <img src={EmptyImage} alt="Slide" className="image" />
            <div className="categories categories-pet">
                <h1 className="pet-title pet-title-empty">Añade tu mascota!</h1>
                <div className="pet-description">
                    <p>Tener mascotas es bonito.</p>
                    <p>¡Nos encantaria ver la tuya!</p>
                    <p>¿Porqué no haces clic en el botón y nos muestras su foto más divertida?</p>
                </div>
            </div>
        </div>
    )
}