import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../components/Loader';
import { Icon } from '@iconify/react';
import "./css/GeneralStyles.css";
import './css/Services.css';

export default function ResetPassword() {
    const navigate = useNavigate();
    const { token, email } = useParams();
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [error, setError] = useState('');

    /**
     * Maneja el envío del formulario para restablecer la contraseña.
     *
     * Params:
     *   e (Event): Evento del formulario enviado.
     * Return: Ninguno. 
     */
    const handleSubmit = (e) => {
        e.preventDefault();

        if (newPassword !== confirmNewPassword) {
            setError('Las nuevas contraseñas no coinciden');
            return;
        }

        const formData = new FormData();
        formData.append('email', email);
        formData.append('token', token);
        formData.append('password', newPassword);


        fetch(process.env.REACT_APP_API_URL + "/api/reset-password", {
            method: "POST",
            body: formData,
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.message) {
                    navigate("/login");
                }
            });
    };

    return (
        <div className="container">
            <div className="header-bar contract-bar">
                <button className="button-bar back-button-bar" size="icon" variant="ghost" onClick={() => navigate("/login")}>
                    <Icon className='icon-bar back-icon-bar' icon="material-symbols:arrow-back-ios" />
                </button>
                <h1 className="title-bar white-bar-title">Cambiar Contraseña</h1>
                <button className="disabled-button" disabled></button>
            </div>
            <div className="session">
                <form onSubmit={handleSubmit} className='content'>
                    {error && <div>{error}</div>}
                    <div className='input-container'>
                        <input
                            type="password"
                            placeholder='Nueva contraseña'
                            className="input"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                        <input
                            type="password"
                            placeholder='Repite la contraseña'
                            className="input"
                            value={confirmNewPassword}
                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div className='log-in-button-container'>
                        <button type="submit">Cambiar contraseña</button>
                    </div>
                </form>
            </div >
        </div >
    );
};