import React, { useEffect } from 'react';
import { GoogleLogin } from 'react-google-login';
import GoogleLogo from '../images/Google-Logo.svg';
import FacebookLogo from '../images/Facebook-Logo.svg';
import AppleLogo from '../images/Apple-Logo.svg';
import { gapi } from "gapi-script";

export default function LoginApiButtons() {
    const clientID = "397341472287-kuqu5d99l5s6ikc98r510avcpebi4tps.apps.googleusercontent.com";

    /**
     * Inicializa la autenticación de Google API utilizando el cliente ID proporcionado.
     */
    useEffect(() => {
        const start = () => {
            gapi.auth2.init({
                clientId: clientID,
            })
        }
        gapi.load("client:auth2", start);
    }, []);

    const responseGoogle = (response) => {
        console.log(response);
        // localStorage.clear();

        // var formDataUser = new FormData();
        // formDataUser.append("email", response.profileObj);
        // formDataUser.append("password", password);

        // fetch(`${process.env.REACT_APP_API_URL}/api/google-login`, {
        //     method: "POST",
        //     body: formDataUser
        // })
        //     .then(response => response.json())
        //     .then(data => {
        //         console.log(data);
        //         if (data !== false) {
        //             localStorage.setItem("cookie_token", data.token);
        //             localStorage.setItem("fullName", data.user.fullName);
        //             localStorage.setItem("avatar", data.user.avatar);
        //             navigate('/dashboard');
        //         } else {
        //             setErrorMessage("El usuario no existe o la contraseña es incorrecta");
        //         }
        //     });
    };

    const onFailure = (error) => {
        console.log(error);

    };

    return (
        <div className='log-in-buttons-container'>
            <button className="log-in-button-session main-button"><img className='logo-buttons-session' src={FacebookLogo} alt="Facebook Logo" /></button>
            {/* <GoogleLogin
                clientId={clientID}
                buttonText="Iniciar sesión con Google"
                onSuccess={responseGoogle}
                onFailure={onFailure}
                cookiePolicy={"single_host_policity"}
                render={renderProps => (
                    <button className="log-in-button-session main-button" onClick={renderProps.onClick}>
                        <img className='logo-buttons-session' src={GoogleLogo} alt="Google Logo" />
                    </button>
                )}
            /> */}
            <button className="log-in-button-session main-button"><img className='logo-buttons-session' src={GoogleLogo} alt="Google Logo" /></button>
            <button className="log-in-button-session main-button"><img className='logo-buttons-session' src={AppleLogo} alt="Apple Logo" /></button>
        </div>
    );
}
