import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Loader from '../components/Loader';
import { Icon } from '@iconify/react';
import "./css/GeneralStyles.css";
import './css/Services.css';
import StyledSelect from '../components/StyledSelect';

export default function SearchServices() {
    const token = localStorage.getItem("cookie_token");
    const location = useLocation();
    const navigate = useNavigate();
    const [loadContent, setLoadContent] = useState(false);
    const [services, setServices] = useState([]);
    const [filteredServices, setFilteredServices] = useState(services);
    const [focus, setFocus] = useState(location.state?.indexFocus ? location.state.indexFocus : 0);
    const options = ['Sin filtro', 'Precio (ASC)', 'Precio (DESC)', 'Valoracion (ASC)'];
    const [selectedFilter, setSelectedFilter] = useState(0);

    /*
     * Maneja cambios en el filtro de servicios, ordenándolos o filtrándolos según la opción seleccionada.
     * Params:
     * - option: La opción seleccionada en el filtro.
     * Return: No tiene retorno explícito, pero actualiza el estado de los servicios filtrados.
     */
    const handleFilterChange = (option) => {
        if (option != selectedFilter) {
            setSelectedFilter(option);
            switch (option) {
                case 0: ;
                    if (focus == 1) {
                        handleFocusButton(1, "Nutritionist", services.unreserved_services);
                    } else if (focus == 2) {
                        handleFocusButton(2, "Educator", services.unreserved_services);
                    } else if (focus == 3) {
                        handleFocusButton(3, "Caregiver", services.unreserved_services);
                    } else {
                        setFilteredServices(services.unreserved_services);
                    }
                    break;
                case 1:
                    setFilteredServices([...filteredServices].sort((a, b) => a.price - b.price));
                    break;
                case 2:
                    setFilteredServices([...filteredServices].sort((a, b) => b.price - a.price));
                    break;
                case 3:
                    setFilteredServices([...filteredServices].sort((a, b) => b.user.average_rating - a.user.average_rating));
                    break;
            }
        }
    };

    /*
     * Realiza una solicitud GET a la API para buscar servicios no reservados cuando el componente se monta por primera vez
    */
    useEffect(() => {
        searchServices();
    }, []);

    /*
     * Función que realiza la búsqueda de servicios no reservados.
     */
    const searchServices = () => {
        fetch(process.env.REACT_APP_API_URL + "/api/search-services", {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setServices(data);
                if (focus == 1) {
                    handleFocusButton(1, "Nutritionist", data.unreserved_services);
                } else if (focus == 2) {
                    handleFocusButton(2, "Educator", data.unreserved_services);
                } else if (focus == 3) {
                    handleFocusButton(3, "Caregiver", data.unreserved_services);
                } else {
                    setFilteredServices(data.unreserved_services);
                }
                setLoadContent(true);
            });
    };

    /**
     * Maneja el cambio de enfoque del botón y filtra los servicios no reservados según la categoría seleccionada.
     *
     * Params: 
     *   - index: El índice del botón en foco.
     *   - category: La categoría seleccionada.
     *   - actualServices: Los servicios actuales para filtrar.
     */
    const handleFocusButton = (index, category, actualServices) => {
        setFocus(index);
        setSelectedFilter(0);

        if (category != "Todos") {
            const filteredTemporalServices = actualServices.filter(service => service.category == category);
            setFilteredServices(filteredTemporalServices);
        } else {
            setFilteredServices(actualServices);
        }
    }

    return (
        <div className="container">
            <div className="content-wo-footer">
                <div className="header-bar contract-bar">
                    <button className="button-bar back-button-bar" size="icon" variant="ghost" onClick={() => navigate("/dashboard")}>
                        <Icon className='icon-bar back-icon-bar' icon="material-symbols:arrow-back-ios" />
                    </button>
                    <h1 className="title-bar white-bar-title">Servicios</h1>
                    <button className="button-bar back-button-bar" size="icon" variant="ghost" onClick={() => navigate("/cards")}>
                        <Icon className='icon-bar' icon="material-symbols:playing-cards-rounded" />
                    </button>
                </div>
                <div className='nav-bar'>
                    <div className='nav-bar-elements'>
                        <button className={'nav-bar-element ' + (focus == 0 && "focus")} onClick={() => handleFocusButton(0, "Todos", services.unreserved_services)}>Todos</button>
                        <button className={'nav-bar-element ' + (focus == 1 && "focus")} onClick={() => handleFocusButton(1, "Nutritionist", services.unreserved_services)}>Nutricionistas</button>
                        <button className={'nav-bar-element ' + (focus == 2 && "focus")} onClick={() => handleFocusButton(2, "Educator", services.unreserved_services)}>Educadores</button>
                        <button className={'nav-bar-element ' + (focus == 3 && "focus")} onClick={() => handleFocusButton(3, "Caregiver", services.unreserved_services)}>Cuidadores</button>
                    </div>
                </div>
                <div className='full-view-services'>
                    <StyledSelect
                        options={options}
                        defaultValue={selectedFilter}
                        onChange={handleFilterChange}
                    />
                    <div className='list-services'>
                        {loadContent ? (
                            <>
                                {filteredServices.map((service, index) => {
                                    return (
                                        <div key={index} className='fade-in-animation'>
                                            <div className='service' onClick={() => navigate("/contract-info", { state: { service: service } })}>
                                                <div className='img-service-container'><img src={service.user.avatar} className="img-profile" /></div>
                                                <div className='service-info'>
                                                    <div>
                                                        <p className='title-user'>{service.user.fullName}</p>
                                                        <p className='job-user'>{service.category}</p>
                                                        <p className='job-user'>{service.price} €</p>
                                                    </div>
                                                    <div className='rating'>
                                                        <Icon icon="tabler:star-filled" className='img-rating' />
                                                        <p>{(Math.round(service.user.average_rating * 10) / 10).toFixed(1)} ({service.user.received_ratings_count} Rewiews)</p>
                                                    </div>
                                                </div>
                                                <div className='go-button'>
                                                    <Icon icon="material-symbols:arrow-forward-ios" />
                                                </div>
                                            </div>
                                            <div className='line-container'><div className='line'></div></div>
                                        </div>
                                    )
                                })}
                            </>
                        ) : (<><Loader /></>)}
                    </div>
                </div>
            </div>
        </div>
    );
};