import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Loader from '../components/Loader';
import Footer from '../components/FooterMenu';
import BackCard from '../components/BackCard';
import Card from '../components/Card';
import FrontCard from '../components/FrontCard';
import "./css/GeneralStyles.css";
import './css/Cards.css';

export default function Cards() {
    const token = localStorage.getItem("cookie_token");
    const navigate = useNavigate();
    const [loadContent, setLoadContent] = useState(false);
    const [services, setServices] = useState([]);
    const [filteredServices, setFilteredServices] = useState(services);
    const [watchedProfiles, setWatchedProfiles] = useState(0);
    const [actualProfile, setActualProfile] = useState(0);
    const [nextProfile, setNextProfile] = useState(1);
    const [startX, setStartX] = useState(null);
    const [offsetX, setOffsetX] = useState(0);
    const cardRef = useRef(null);

    /**
     * Realiza una búsqueda de servicios al cargar el componente.
     */
    useEffect(() => {
        searchServices();
    }, []);

    /**
     * Realiza una búsqueda de los servicios principales.
     */
    const searchServices = () => {
        fetch(process.env.REACT_APP_API_URL + "/api/search-top-services", {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setServices(data);
                setFilteredServices(data);

                setLoadContent(true);
            });
    };

    /**
     * Avanza al siguiente perfil.
     * 
     * Params: connect (boolean) - Indica si se conectará con el perfil siguiente.
     * Return: Ninguno.
     */
    const goNextProfile = (connect) => {
        setWatchedProfiles(watchedProfiles + 1);
        if (connect) {
            let constructService = filteredServices[actualProfile].service;
            constructService.user = filteredServices[actualProfile].user;
            navigate("/contract-info", { state: { service: constructService, cards: true } });
        } else {
            saveWatchedService(filteredServices[actualProfile].service.id);
        }

        const nextNum = (actualProfile + 1);
        const nextNextNum = (nextNum + 1);

        setActualProfile(nextNum);
        setNextProfile(nextNextNum);
    };

    /**
     * Guarda el servicio observado.
     * 
     * Params: idService (string) - ID del servicio observado.
     * Return: Ninguno.
     */
    const saveWatchedService = (idService) => {
        fetch(process.env.REACT_APP_API_URL + "/api/save-watched-service/" + idService, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
            });
    }

    return (
        <div className="container alice-content">
            <div className="content-wo-footer wo-overflow">
                <div className="cards-container fade-in-animation">
                    {loadContent ? (filteredServices.length > 0 ? (
                        <>
                            {
                                filteredServices.length > 0 && actualProfile < filteredServices.length && (
                                    <>
                                        <FrontCard setStartX={setStartX} offsetX={offsetX} setOffsetX={setOffsetX} goNextProfile={goNextProfile} cardRef={cardRef} startX={startX} />
                                        <Card service={filteredServices[actualProfile].service} user={filteredServices[actualProfile].user} offsetX={offsetX} cardRef={cardRef} />
                                    </>
                                )
                            }
                            {(filteredServices.length - watchedProfiles) > 1 && (
                                <BackCard service={filteredServices[nextProfile].service} user={filteredServices[nextProfile].user} />
                            )}
                            <div className='card box-shadow'>
                                <div className="center-container">
                                    {(filteredServices.length - watchedProfiles) == 0 && <h4 className="fade-in-animation" style={{ textAlign: "center" }}>No hay mas servicios disponibles</h4>}
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className='card box-shadow fade-in-animation'>
                            <div className="center-container">
                                <h4>Cards no disponibles</h4>
                            </div>
                        </div>
                    )) : (<><Loader /></>)}
                </div>
            </div>
            <Footer></Footer>
        </div >
    );
};