import React from 'react';
import './css/Profile.css';
import './css/GeneralStyles.css';
import { useNavigate } from 'react-router-dom';
import AuthAdmin from '../components/AuthAdmin';
import { Icon } from '@iconify/react';

export default function AdminConfig() {
    const navigate = useNavigate();
    const isAdmin = AuthAdmin();

    if (isAdmin) {
        return (
            <div className="container">
                <div className="content-wo-footer">
                    <div className="header-bar blue-bar">
                        <button className="button-bar blue-bar-title" size="icon" variant="ghost" onClick={() => navigate("/profile")}>
                            <Icon className='icon-bar back-icon-bar' icon="material-symbols:arrow-back-ios" />
                        </button>
                        <h1 className="title-bar blue-bar-title">Admin</h1>
                        <button className="disabled-button" disabled></button>
                    </div>
                    <div className='config-buttons-container'>
                        <div className='config-general'>
                            <div className='title-config'><h2>Panel de administración</h2></div>
                            <button className="button-config size-not-effect" onClick={() => navigate("/admin-notifications")}><div className='element-button-config'><Icon className='icon-config' icon="tabler:bell-cog" /><p>Administrar notificaciones</p></div><Icon icon="material-symbols:arrow-forward-ios" /></button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};