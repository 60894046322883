import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import FormComponent from '../components/FormComponent';
import './css/AddService.css';

export default function EditService() {
    const location = useLocation();
    const service = location.state ? location.state.service : navigate("profile");;
    const navigate = useNavigate();
    const token = localStorage.getItem("cookie_token");
    const [showModal, setShowModal] = useState(false);
    const [errorModal, setErrorModal] = useState(false);

    const [description, setDescription] = useState('');
    const [inputForm, setInputForm] = useState(null);
    const [category, setCategory] = useState('');
    const [animalType, setAnimalType] = useState('');
    const [schedule, setSchedule] = useState('');
    const [price, setPrice] = useState('');
    const [municipality, setMunicipality] = useState('');
    const [homeService, setHomeService] = useState(false);
    const [maxPets, setMaxPets] = useState('');

    /**
     * Establece los valores iniciales de los campos del formulario con los datos del servicio.
     */
    useEffect(() => {
        console.log(service);
        setDescription(service.description);
        setCategory(service.category);
        setAnimalType(service.animal_type);
        setSchedule(JSON.parse(service.schedule));
        setPrice(service.price);
        setMunicipality(service.municipality);
        setHomeService(service.home_service);
        setMaxPets(service.max_pets);
    }, []);

    /**
     * Maneja la edición del servicio.
     * 
     * Params: e (event) - El evento de formulario.
     * Return: Ninguno.
     */
    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('id', service.id);
        formData.append('category', category);
        formData.append('description', description);
        formData.append('animal_type', animalType);
        formData.append('schedule', JSON.stringify(schedule));
        formData.append('price', price);
        formData.append('municipality', municipality);
        formData.append('home_service', homeService ? '1' : '0');
        formData.append('max_pets', maxPets);

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/api/edit-service", {
                method: "POST",
                body: formData,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                },
            });

            const data = await response.json();
            if (!data.error) {
                navigate("/profile");
            } else {
                // Manejar el error si es necesario
            }
        } catch (error) {
            console.error("Error al editar el servicio:", error);
            // Manejar el error si es necesario
        }
    };

    /**
     * Configura el modal de entrada.
     * 
     * Params: inputType (string) - El tipo de entrada.
     *         variable (any) - La variable que se actualizará con la entrada.
     *         setVariable (function) - La función para actualizar la variable.
     *         placeholder (string) - El marcador de posición para la entrada.
     * Return: Ninguno.
     */
    const setInputModal = (inputType, variable, setVariable, placeholder) => {
        setInputForm(<FormComponent inputType={inputType} variable={variable} setVariable={setVariable} setShowModal={setShowModal} placeholder={placeholder} />);
        setShowModal(true);
    }

    return (
        <div className="container">
            <div className="content-wo-footer">
                <div className="header-bar cian-bar">
                    <button className="button-bar blue-bar-title" size="icon" variant="ghost" onClick={() => navigate("/my-services")}>
                        <Icon className='icon-bar back-icon-bar' icon="material-symbols:arrow-back-ios" />
                    </button>
                    <h1 className="title-bar blue-bar-title">Editar Servicio</h1>
                    <button className="disabled-button" disabled></button>
                </div>
                <div className="profile-element">
                    <div className="image-profile-element">
                        <img
                            alt="Avatar"
                            className="image-profile"
                            src={"https://source.unsplash.com/random"}
                        />
                        <button className="button-profile-element" size="icon" variant="ghost" onClick={() => console.log("hola")}>
                            <Icon icon="tabler:edit" className='icon-edit' />
                            <span className="sr-only">Editar</span>
                        </button>
                    </div>
                    <div className="name-container">
                        <h1></h1>
                    </div>
                </div>
                <div className='config-buttons-container'>
                    <div className='config-general'>
                        <div className='title-config'><h2>General</h2></div>
                        <button className="button-config size-not-effect" onClick={() => setInputModal("category", category, setCategory)}>
                            <div className='element-button-config'>
                                <Icon className='icon-config' icon="tabler:paw" />
                                <p>Categoría</p>
                            </div>
                            <Icon icon="material-symbols:arrow-forward-ios" />
                        </button>
                        <button className="button-config size-not-effect" onClick={() => setInputModal("description", description, setDescription)}>
                            <div className='element-button-config'>
                                <Icon className='icon-config' icon="tabler:text-caption" />
                                <p>Descripción</p>
                            </div>
                            <Icon icon="material-symbols:arrow-forward-ios" />
                        </button>
                        <button className="button-config size-not-effect" onClick={() => setInputModal("animal-type", animalType, setAnimalType)}>
                            <div className='element-button-config'>
                                <Icon className='icon-config' icon="tabler:dog" />
                                <p>Tipo de animal</p>
                            </div>
                            <Icon icon="material-symbols:arrow-forward-ios" />
                        </button>
                        <button className="button-config size-not-effect" onClick={() => setInputModal("max-pets", maxPets, setMaxPets)}>
                            <div className='element-button-config'>
                                <Icon className='icon-config' icon="tabler:dog" />
                                <p>Maximo de Mascotas</p>
                            </div>
                            <Icon icon="material-symbols:arrow-forward-ios" />
                        </button>
                    </div>
                </div>
                <div className='config-buttons-container'>
                    <div className='config-general'>
                        <div className='title-config'><h2>Lugar y horarios</h2></div>
                        <button className="button-config size-not-effect" onClick={() => setInputModal("schedule", schedule, setSchedule)}>
                            <div className='element-button-config'>
                                <Icon className='icon-config' icon="tabler:calendar" />
                                <p>Dias de la semana</p>
                            </div>
                            <Icon icon="material-symbols:arrow-forward-ios" />
                        </button>
                        <button className="button-config size-not-effect" onClick={() => setInputModal("place", municipality, setMunicipality)}>
                            <div className='element-button-config'>
                                <Icon className='icon-config' icon="tabler:location" />
                                <p>Lugar</p>
                            </div>
                            <Icon icon="material-symbols:arrow-forward-ios" />
                        </button>
                        <button className="button-config size-not-effect" onClick={() => setHomeService(!homeService)}>
                            <div className='element-button-config'>
                                <Icon className='icon-config' icon="tabler:home" />
                                <p>Servicio a domicilio</p>
                            </div>
                            <input type='checkbox' value={homeService} checked={homeService} onChange={() => setHomeService(!homeService)} />
                        </button>
                        <button className="button-config size-not-effect" onClick={() => setInputModal("price", price, setPrice)}>
                            <div className='element-button-config'>
                                <Icon className='icon-config' icon="tabler:coin-euro" />
                                <p>Precio €/hora</p>
                            </div>
                            <Icon icon="material-symbols:arrow-forward-ios" />
                        </button>
                    </div>
                    <button className='add-service-button' onClick={handleSubmit}>Editar el servicio</button>
                </div>
            </div>
            {showModal && (
                <div className="modal-bg">
                    <div className="modal">
                        {inputForm}
                    </div>
                </div>
            )}
            {errorModal && (
                <div className="modal-bg" onClick={() => setErrorModal(false)}>
                    <div className="modal">
                        <h3>Completa todos los campos correctamente</h3>
                    </div>
                </div>
            )}
        </div>
    );
}
