import React, { useState, useEffect, useRef } from 'react';
import './css/StyledSelect.css';
import { Icon } from '@iconify/react';

const StyledSelect = ({ options, defaultValue, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const selectRef = useRef(null);

    /**
     * Establece el valor seleccionado cuando el defaultValue cambia.
     */
    useEffect(() => {
        setSelectedOption(defaultValue);
    }, [defaultValue]);

    /**
     * Maneja la selección de una opción en el componente select.
     *
     * Params:
     *   - index: El índice de la opción seleccionada.
     */
    const handleSelect = (index) => {
        setIsOpen(false);
        onChange(index);
    };

    /**
     * Maneja el evento de clic fuera del componente select para cerrar el menú desplegable.
     *
     * Params:
     *   - event: El evento de clic.
     */
    const handleClickOutside = (event) => {
        if (selectRef.current && !selectRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    /**
     * Agrega un event listener para cerrar el menú desplegable cuando se hace clic fuera de él.
     */
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div ref={selectRef} className={`custom-select ${isOpen ? 'open' : ''}`}>
            <div className="select-selected" onClick={() => setIsOpen(!isOpen)}>
                {options[selectedOption]}
                <div className='expand'><Icon icon="material-symbols:expand-more" /></div>
            </div>
            <div className="select-items">
                {options.map((option, index) => (
                    <div key={index} onClick={() => handleSelect(index)}>
                        {option}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default StyledSelect;
