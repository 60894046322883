import React, { useState, useEffect } from 'react';
import Mapa from "../components/Mapa.js";
import "./css/GeneralStyles.css";
import "./css/EmergencyPage.css";
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/FooterMenu';

export default function EmergencyPage() {
    const [places, setPlaces] = useState([]);
    const [mapPage, setMapPage] = useState(true);
    const [currentLocation, setCurrentLocation] = useState(null);

    /**
     * Utiliza el efecto para obtener la ubicación actual del usuario si el navegador lo permite.
     * 
     * Si el navegador admite la geolocalización, obtiene la posición actual del usuario y actualiza el estado
     * de la ubicación actual con las coordenadas de latitud y longitud.
     * 
     * Si la geolocalización no es compatible con el navegador, muestra un mensaje de error en la consola.
     * 
     * Este efecto se ejecuta una vez al montar el componente.
     */
    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setCurrentLocation({ lat: latitude, lng: longitude });
                },
                (error) => {
                    console.error('Error getting the current location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    }, []);

    const mapStyles = {
        height: '100%',
        width: '100%',
    };


    return (
        <div className='container'>
            {/* <button className="small-button small-button-back" onClick={() => navigate("/profile")}><Icon icon="tabler:arrow-left" className='icon' /></button >
            {mapPage ? (
                <button className="small-button small-button-accion" onClick={() => setMapPage(false)}>LLAMA A UN VETERINARIO AHORA</button >
            ) : (
                <button className="small-button small-button-mapa" onClick={() => setMapPage(true)}><Icon icon="tabler:map" className="icon" /></button >
            )} */}

            {mapPage ? (
                <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={13}
                    center={currentLocation || { lat: 0, lng: 0 }}
                    options={{
                        disableDefaultUI: true,
                        clickableIcons: false,
                        suppressMarkers: true
                    }}
                >
                    <Mapa places={places} setPlaces={setPlaces} currentLocation={currentLocation} setCurrentLocation={setCurrentLocation} />
                </GoogleMap >
            ) : (
                <div className='container'>
                    <div className="session">
                        <div className='content'>
                            <h4 className='log-in-title'>Veterinarios Disponibles</h4>
                            <div className='veterinarios-list-container'>
                                {places.map((place, index) => (
                                    <div key={`place_${index}`} className='veterinario-item'>
                                        <h5 className='veterinario-name'>{place.name}</h5>
                                        <p className='veterinario-address'>Valoración: {place.rating}</p>
                                        <p className='veterinario-phone'>{place.info?.formatted_phone_number ? (place.info.formatted_phone_number) : "No disponible"}</p>
                                        {place.info?.formatted_phone_number && <a href={`tel:${place.info.formatted_phone_number}`} className='call-button call-button-list'><Icon icon="tabler:phone" className='icon' /></a>}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Footer></Footer>
        </div>
    );
}
